import React, { ChangeEvent, useRef } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { LocationsTable } from './tables';
import QuoteActions from './QuoteActions';
import QuoteSummary from './QuoteSummary';
import WirelessQuoteMessage from './WirelessQuoteMessage';
import { QuoteEmailTable, QuoteEmailTableRef } from './QuoteEmailTable';
import { calculateTotals } from '../../../utils/quoteUtils';
import { MainTabProps } from '../../../types';
import { invalidateQuoteCache } from '../../../utils/api';
import {
  Row,
  Col,
  Alert,
  Space,
  Input,
  Text
} from '../../../components/shared/AntComponents';

const MainTab: React.FC<MainTabProps> = ({
  accountName,
  rawQuoteData,
  locations,
  lineItems,
  error,
  expandAll,
  isEditingSubject,
  editSubjectValue,
  owninguser,
  id,
  accountId,
  show,
  toggleExpandAll,
  refetch,
  setEditSubjectValue,
  setIsEditingSubject,
  handleAddLineItem,
  handleDeleteLocation,
  handleUpdateLineItem,
  handleDeleteLineItem,
  updateQuoteRequest,
  userAccessLevel,
}) => {
  const isWirelessQuote = rawQuoteData.quoteRequest?.foxy_quotetype === 612100001;
  const emailTableRef = useRef<QuoteEmailTableRef>(null);

  const handleSubjectUpdate = async () => {
    if (editSubjectValue !== rawQuoteData.quoteRequest?.foxy_subject) {
      try {
        await updateQuoteRequest(id, { foxy_subject: editSubjectValue });
        invalidateQuoteCache(id);
        // Then refetch in the background
        refetch();
      } catch (error) {
        console.error('Update subject error:', error);
      }
      // Only set editing to false after the update is complete
      setIsEditingSubject(false);
    } else {
      setIsEditingSubject(false);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEditSubjectValue(e.target.value);
  };

  const handleOpticQuoteEdit = async (value: string) => {
    try {
      await updateQuoteRequest(id, { foxy_opticquote: value });
      invalidateQuoteCache(id);
      // Then refetch in background
      refetch();
    } catch (error) {
      console.error('Update OptiC Quote error:', error);
    }
  };

  const handleLineCommitEdit = async (value: string) => {
    try {
      await updateQuoteRequest(id, { foxyflow_voicedatacommit: value });
      invalidateQuoteCache(id);
      // Then refetch in background
      refetch();
    } catch (error) {
      console.error('Update Line Commit error:', error);
    }
  };

  const totals = calculateTotals(lineItems);

  return (
    <Row gutter={[0, 16]}>
      <Col span={24} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <div style={{ marginBottom: '8px' }}>
          <Text strong style={{ fontSize: '16px', display: 'block' }}>{accountName}</Text>
          <Space>
            {isEditingSubject ? (
              <Input
                value={editSubjectValue}
                onChange={handleChange}
                onPressEnter={handleSubjectUpdate}
                onBlur={handleSubjectUpdate}
                autoFocus
                style={{ minWidth: '500px' }}
              />
            ) : (
              <div 
                onClick={() => {
                  setEditSubjectValue(rawQuoteData.quoteRequest?.foxy_subject || '');
                  setIsEditingSubject(true);
                }}
                style={{ 
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  maxWidth: '500px'
                }}
              >
                <Text 
                  type="secondary" 
                  style={{ 
                    fontSize: '14px',
                    display: 'inline-block'
                  }}
                >
                  {rawQuoteData.quoteRequest?.foxy_subject || '-'}
                </Text>
                <EditOutlined style={{ color: '#00000073', flexShrink: 0 }} />
              </div>
            )}
          </Space>
        </div>
        <QuoteActions 
          onAddLocation={show}
          onToggleExpand={toggleExpandAll}
          expandAll={expandAll}
          quoteStage={rawQuoteData.quoteRequest?.foxy_quotestage || 0}
          quoteId={id}
          onRefresh={refetch}
          locations={locations}
          lineItems={lineItems}
          accountId={accountId}
          accountName={accountName}
          opportunityId={rawQuoteData.quoteRequest?._foxy_opportunity_value}
          rawQuoteData={rawQuoteData}
          onCopyToEmail={() => emailTableRef.current?.copyToClipboard()}
        />
      </Col>
      <Col span={24}>
        <QuoteSummary 
          owner={owninguser?.fullname || ''} 
          totalMRR={totals.totalMRR} 
          totalTCV={totals.totalTCV}
          quoteStage={rawQuoteData.quoteRequest?.foxy_quotestage || 0}
          quoteType={rawQuoteData.quoteRequest?.foxy_quotetype || 0}
          opticQuote={rawQuoteData.quoteRequest?.foxy_opticquote || ''}
          onOpticQuoteEdit={handleOpticQuoteEdit}
          lineCommit={rawQuoteData.quoteRequest?.foxyflow_voicedatacommit || '0'}
          onLineCommitEdit={handleLineCommitEdit}
        />
      </Col>
      {error && (
        <Col span={24}>
          <Alert message="Error" description={error} type="error" showIcon />
        </Col>
      )}
      <Col span={24}>
        {isWirelessQuote ? (
          <WirelessQuoteMessage quoteId={id} />
        ) : (
          <LocationsTable
            data={locations}
            lineItems={lineItems}
            onAddLine={handleAddLineItem}
            expandAll={expandAll}
            onDeleteLocation={handleDeleteLocation}
            onUpdateLineItem={handleUpdateLineItem}
            onDeleteLineItem={handleDeleteLineItem}
            quoteStage={rawQuoteData.quoteRequest?.foxy_quotestage || 0}
            userAccessLevel={userAccessLevel}
          />
        )}
      </Col>
      {/* Hidden email table for copying */}
      <QuoteEmailTable ref={emailTableRef} locations={locations} lineItems={lineItems} />
    </Row>
  );
};

export default MainTab;
