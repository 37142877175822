import React, { useEffect, useState } from 'react';
import { Layout, Dropdown, Avatar, Button, Modal, Form, Input } from 'antd';
import type { MenuProps } from 'antd';
import { 
  UserOutlined, 
  AppstoreOutlined,
  TeamOutlined,
  BugOutlined
} from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { checkUserAccess, UserAccessLevel } from '../../auth/authService';
import { API_BASE_URL, getNoCacheHeaders } from '../../utils/api';
import './Header.css';

const { Header } = Layout;

interface AppHeaderProps {
  quoteRequestId?: string;
  newProp?: string;
}

const AppHeader: React.FC<AppHeaderProps> = () => {
  const [_userAccess, setUserAccess] = useState<UserAccessLevel>('none');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isBugModalVisible, setIsBugModalVisible] = useState(false);
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const fetchUserAccess = async () => {
      const access = await checkUserAccess();
      setUserAccess(access);
    };
    fetchUserAccess();
  }, []);

  const getMenuItems = (): Required<MenuProps>['items'] => {
    if (!id) {
      return [];
    }

    return [
      {
        key: 'view-in-crm',
        label: (
          <a 
            href={`https://foxy.crm3.dynamics.com/main.aspx?appid=a5e9eec5-dda4-eb11-9441-000d3a848fc5&forceUCI=1&pagetype=entityrecord&etn=foxy_foxyquoterequest&id=${id}`}
          >
            View in Foxy CRM
          </a>
        ),
        icon: <TeamOutlined />,
      }
    ];
  };

  const menuItems = getMenuItems();

  const handleBugSubmit = async (values: any) => {
    try {
      const headers = await getNoCacheHeaders();
      
      const bugData = {
        ...values,
        url: window.location.href,
        userAgent: navigator.userAgent,
        timestamp: new Date().toISOString()
      };
      
      const response = await fetch(`${API_BASE_URL}/createJiraIssue`, {
        method: 'POST',
        headers: {
          ...headers,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bugData)
      });

      if (!response.ok) {
        throw new Error('Failed to submit bug report');
      }

      const result = await response.json();
      
      Modal.success({
        title: 'Bug Report Submitted',
        content: (
          <div>
            <p>Thank you for your bug report!</p>
            <p>You can track this issue at: <a href={result.issueUrl} target="_blank" rel="noopener noreferrer">{result.issueKey}</a></p>
          </div>
        ),
      });
      
      form.resetFields();
      setIsBugModalVisible(false);
    } catch (error) {
      console.error('Error submitting bug:', error);
      Modal.error({
        title: 'Error',
        content: 'Failed to submit bug report. Please try again later.',
      });
    }
  };

  return (
    <Header className="app-header">
      {/* Menu and Logo section */}
      <div className="header-left">
        {menuItems.length > 0 && (
          <Dropdown 
            menu={{ items: menuItems }}
            trigger={['click']}
            placement="bottomLeft"
            onOpenChange={setIsMenuOpen}
          >
            <div className={`menu-icon ${isMenuOpen ? 'open' : ''}`}>
              <AppstoreOutlined />
            </div>
          </Dropdown>
        )}
        <div className="logo-container">
          <img src="/foxylogo.png" alt="Foxy Logo" className="logo-image" />
          <span className="logo-text">CPQ</span>
        </div>
      </div>

      {/* Avatar section */}
      <div className="header-right">
        <Button 
          type="text"
          icon={<BugOutlined />}
          onClick={() => setIsBugModalVisible(true)}
          className="bug-submit-button"
        >
          Submit Bug
        </Button>
        <div className="user-avatar">
          <Avatar 
            icon={<UserOutlined />} 
            className="avatar-icon"
          />
        </div>
      </div>

      {/* Bug Submit Modal */}
      <Modal
        title="Submit Bug Report"
        open={isBugModalVisible}
        onCancel={() => setIsBugModalVisible(false)}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleBugSubmit}
        >
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: 'Please enter a title' }]}
          >
            <Input placeholder="Brief description of the issue" />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: 'Please describe the issue' }]}
          >
            <Input.TextArea 
              rows={4}
              placeholder="Please provide details about what happened and what you expected to happen"
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Header>
  );
};

export default AppHeader;
