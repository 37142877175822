import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Row, Col, Card } from 'antd';
import { usePlanalyzer } from './hooks/usePlanalyzer';
import { useClipboard } from './hooks/useClipboard';
import { calculateTotal } from './utils/calculations';
import PlanalyzerHeader from './PlanalyzerHeader';
import Summary from './components/Summary';
import PlanSection from './components/PlanSection';
import ActionButtons from './components/ActionButtons';
import FixedCostsPanel from './components/FixedCostsPanel';

const { Title } = Typography;

const Planalyzer: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const {
    currentFixedCosts,
    newFixedCosts,
    currentPlans,
    newPlans,
    handleFixedCostChange,
    handlePlanChange,
    addPlan,
    removePlan,
    handleSave,
    setNewFixedCosts
  } = usePlanalyzer(id);

  const state = {
    currentFixedCosts,
    newFixedCosts,
    currentPlans,
    newPlans
  };

  const {
    copyTableToClipboard,
    copyNewTableToClipboard
  } = useClipboard(state);

  const [fixedCostsActiveKey, setFixedCostsActiveKey] = useState<string[]>(['1']);

  useEffect(() => {
    document.title = 'Planalyzer';
    return () => {
      document.title = 'Foxy Ledger';
    };
  }, []);

  const handleFixedCostsCollapse = (key: string[]) => {
    setFixedCostsActiveKey(key);
  };

  return (
    <>
      <PlanalyzerHeader />
      <div style={{ padding: '24px 50px' }}>
        <Row gutter={24}>
          {/* Current Side */}
          <Col span={12}>
            <Card title={<Title level={3}>Current</Title>}>
              <FixedCostsPanel
                title="Usage Based Costs"
                fixedCosts={currentFixedCosts}
                onFixedCostChange={(field, e) => handleFixedCostChange('current', field, e)}
                showCopyButtons
                onCopyToNew={(field) => {
                  setNewFixedCosts(prev => ({
                    ...prev,
                    [field]: currentFixedCosts[field]
                  }));
                }}
                activeKey={fixedCostsActiveKey}
                onCollapse={handleFixedCostsCollapse}
              />
              <PlanSection
                title="Current Plans"
                plans={currentPlans}
                onAddPlan={() => addPlan('current')}
                onRemovePlan={(index) => removePlan('current', index)}
                onChange={(index, field, value) => handlePlanChange('current', index, field, value)}
                isCurrentPlan={true}
              />
            </Card>
          </Col>

          {/* New Side */}
          <Col span={12}>
            <Card title={<Title level={3}>New</Title>}>
              <FixedCostsPanel
                title="Usage Based Costs"
                fixedCosts={newFixedCosts}
                onFixedCostChange={(field, e) => handleFixedCostChange('new', field, e)}
                activeKey={fixedCostsActiveKey}
                onCollapse={handleFixedCostsCollapse}
              />
              <PlanSection
                title="New Plans"
                plans={newPlans}
                onAddPlan={() => addPlan('new')}
                onRemovePlan={(index) => removePlan('new', index)}
                onChange={(index, field, value) => handlePlanChange('new', index, field, value)}
                isCurrentPlan={false}
              />
            </Card>
          </Col>
        </Row>

        <ActionButtons
          onCopyTable={copyTableToClipboard}
          onCopyNewTable={copyNewTableToClipboard}
          onSave={handleSave}
        />

        <Summary 
          currentTotal={calculateTotal(currentPlans, currentFixedCosts)}
          newTotal={calculateTotal(newPlans, newFixedCosts)}
          currentPlans={currentPlans}
          newPlans={newPlans}
          currentFixedCosts={currentFixedCosts}
          newFixedCosts={newFixedCosts}
        />
      </div>
    </>
  );
};

export default Planalyzer;
