import React from 'react';
import { Button, Space, Modal, Tooltip, message } from 'antd';
import { 
  PlusOutlined, 
  ExpandAltOutlined, 
  ShrinkOutlined, 
  CopyOutlined, 
  RollbackOutlined, 
  CheckCircleOutlined, 
  FileTextOutlined,
  FileExcelOutlined 
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { 
  createQuoteRequest, 
  createFoxyQuoteRequestLocation, 
  updateQuoteRequest, 
  createQuoteLineItem 
} from '../../../utils/api';
import { exportToExcel } from '../../../utils/excelExport';
import { QuoteActionsProps } from '../../../types';
import { validateQuoteReadyForSubmit } from './utils';

const QuoteActions: React.FC<QuoteActionsProps> = ({
  onAddLocation,
  onToggleExpand,
  expandAll,
  quoteStage,
  quoteId,
  onRefresh,
  locations,
  lineItems,
  accountId,
  accountName,
  opportunityId,
  rawQuoteData,
  onCopyToEmail
}) => {
  const navigate = useNavigate();
  const showQuoteActionButton = [612100000, 612100001, 612100002].includes(quoteStage);
  const isSubmitStage = quoteStage === 612100000 || quoteStage === 612100002;
  const isQuoteValid = validateQuoteReadyForSubmit(locations, lineItems);
  const isWirelessQuote = rawQuoteData.quoteRequest?.foxy_quotetype === 612100001;
  const showAddLocation = (quoteStage === 612100000 || quoteStage === 612100002) && !isWirelessQuote;

  const handleQuoteAction = async () => {
    const isSubmit = quoteStage === 612100000;
    const isResubmit = quoteStage === 612100002;
    const isRecall = quoteStage === 612100001;
    
    Modal.confirm({
      title: isSubmit ? 'Submit Quote' : isResubmit ? 'Resubmit Quote' : 'Recall Quote',
      content: isSubmit ? 
        'Are you sure you want to submit this quote?' : 
        isResubmit ?
        'Are you sure you want to resubmit this quote?' :
        'Are you sure you want to recall this quote?',
      onOk: async () => {
        try {
          if (!quoteId) {
            message.error('Quote ID is missing');
            return;
          }

          await updateQuoteRequest(quoteId, {
            foxy_quotestage: isRecall ? 612100000 : 612100001
          });

          // Update local state immediately
          if (rawQuoteData.quoteRequest) {
            rawQuoteData.quoteRequest.foxy_quotestage = isRecall ? 612100000 : 612100001;
          }

          message.success(`Quote ${isSubmit ? 'submitted' : isResubmit ? 'resubmitted' : 'recalled'} successfully`);
          // Then refetch in background
          onRefresh();
        } catch (error) {
          console.error('Error updating quote stage:', error);
          message.error(`Failed to ${isSubmit ? 'submit' : isResubmit ? 'resubmit' : 'recall'} quote`);
        }
      }
    });
  };

  const handleCloneQuote = () => {
    Modal.confirm({
      title: 'Clone Quote',
      content: 'Are you sure you want to clone this quote?',
      onOk: async () => {
        const hide = message.loading('Creating new quote...', 0);
        try {
          // Validate required data
          if (!accountId || !opportunityId) {
            hide();
            message.error('Account ID and Opportunity ID are required');
            return;
          }

          const originalQuoteId = rawQuoteData?.quoteRequest?.foxy_quoteid;
          const originalSubject = rawQuoteData?.quoteRequest?.foxy_subject;
          
          if (!originalSubject) {
            hide();
            message.error('Original quote subject is missing');
            return;
          }

          const newSubject = `${originalSubject} (Cloned from ${originalQuoteId || 'unknown'})`;

          console.log('Creating new quote with:', {
            accountId,
            opportunityId,
            subject: newSubject,
            quoteType: rawQuoteData?.quoteRequest?.foxy_quotetype
          });

          const newQuote = await createQuoteRequest({
            _foxy_account_value: accountId,
            _foxy_opportunity_value: opportunityId,
            foxy_subject: newSubject,
            foxy_quotetype: rawQuoteData?.quoteRequest?.foxy_quotetype
          });

          if (!newQuote?.foxy_foxyquoterequestid) {
            hide();
            throw new Error('Failed to create new quote request - no ID returned');
          }

          console.log('New quote created:', newQuote.foxy_foxyquoterequestid);
          hide();
          const cloneHide = message.loading('Cloning locations and line items...', 0);

          // Process locations sequentially
          for (const [index, location] of locations.entries()) {
            try {
              console.log(`Creating location ${index + 1} of ${locations.length}:`, {
                buildingId: location._foxy_building_value,
                quoteId: newQuote.foxy_foxyquoterequestid,
                accountLocationId: location._foxy_companylocation_value
              });
              
              const newLocation = await createFoxyQuoteRequestLocation(
                location._foxy_building_value,
                newQuote.foxy_foxyquoterequestid,
                location._foxy_companylocation_value
              );

              if (!newLocation?.foxy_foxyquoterequestlocationid) {
                throw new Error('Failed to create new location - no ID returned');
              }

              console.log('New location created:', newLocation.foxy_foxyquoterequestlocationid);

              // Get line items for this location
              const locationLineItems = lineItems[location.foxy_foxyquoterequestlocationid] || [];

              // Process line items for this location sequentially
              for (const [itemIndex, item] of locationLineItems.entries()) {
                if (!item._foxy_product_value) {
                  console.warn('Skipping line item without product value:', item);
                  continue;
                }

                try {
                  console.log(`Creating line item ${itemIndex + 1} of ${locationLineItems.length}:`, {
                    locationId: newLocation.foxy_foxyquoterequestlocationid,
                    productId: item._foxy_product_value,
                    data: {
                      quantity: item.foxy_quantity,
                      each: item.foxy_each,
                      mrr: item.foxy_mrr,
                      linetcv: item.foxy_linetcv,
                      term: item.foxy_term,
                      revenuetype: item.foxy_revenuetype,
                      renewaltype: item.foxy_renewaltype,
                      renewaldate: item.foxy_renewaldate,
                      existingqty: item.foxy_existingqty,
                      existingmrr: item.foxy_existingmrr
                    }
                  });
                  
                  const newLineItem = await createQuoteLineItem({
                    _foxy_foxyquoterequestlocation_value: newLocation.foxy_foxyquoterequestlocationid,
                    _foxy_product_value: item._foxy_product_value,
                    foxy_quantity: item.foxy_quantity,
                    foxy_each: item.foxy_each,
                    foxy_mrr: item.foxy_mrr,
                    foxy_linetcv: item.foxy_linetcv,
                    foxy_term: item.foxy_term,
                    foxy_revenuetype: item.foxy_revenuetype,
                    foxy_renewaltype: item.foxy_renewaltype,
                    foxy_renewaldate: item.foxy_renewaldate,
                    foxy_existingqty: item.foxy_existingqty,
                    foxy_existingmrr: item.foxy_existingmrr
                  });

                  if (!newLineItem) {
                    throw new Error('Failed to create line item - no response');
                  }

                  console.log('Line item created successfully');
                } catch (err) {
                  const error = err as Error;
                  console.error('Error creating line item:', error);
                  throw new Error(`Failed to create line item ${itemIndex + 1} for product ${item._foxy_product_value}: ${error.message}`);
                }
              }
            } catch (err) {
              const error = err as Error;
              console.error('Error processing location:', error);
              throw new Error(`Failed to process location ${index + 1} (${location._foxy_building_value}): ${error.message}`);
            }
          }

          cloneHide();
          message.success('Quote cloned successfully');
          navigate(`/quote/${newQuote.foxy_foxyquoterequestid}`);
        } catch (err) {
          const error = err as Error;
          hide();
          message.destroy(); // Clear any lingering messages
          console.error('Clone error:', error);
          
          // Extract the most relevant part of the error message
          const errorMessage = error.message.includes('Failed to') 
            ? error.message 
            : `Failed to clone quote: ${error.message}`;
            
          message.error(errorMessage);
        }
      }
    });
  };

  const handleExportExcel = () => {
    if (!quoteId) {
      message.error('Quote ID is missing');
      return;
    }
    try {
      exportToExcel(
        locations, 
        lineItems, 
        rawQuoteData.quoteRequest?.foxy_quoteid || quoteId,
        accountName || 'Unknown'
      );
      message.success('Quote exported to Excel successfully');
    } catch (error) {
      console.error('Error exporting to Excel:', error);
      message.error('Failed to export quote to Excel');
    }
  };

  return (
    <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
      {showQuoteActionButton && (
        <Tooltip title={
          !isQuoteValid && isSubmitStage ? 
          "Quote cannot be submitted. Please ensure: \n• At least one location is added\n• Each location has at least one product\n• All required fields are filled in for each product" 
          : ""
        }>
          <Button 
            type="primary"
            icon={quoteStage === 612100001 ? <RollbackOutlined /> : <CheckCircleOutlined />}
            onClick={handleQuoteAction}
            disabled={!isQuoteValid}
          >
            {quoteStage === 612100000 ? 'Submit Quote' : 
             quoteStage === 612100002 ? 'Resubmit Quote' : 
             'Recall Quote'}
          </Button>
        </Tooltip>
      )}
      {!isWirelessQuote && (
        <>
          <Tooltip title={
            !isQuoteValid ? 
            "Cannot copy to email. Please ensure: \n• At least one location is added\n• Each location has at least one product\n• All required fields are filled in for each product" 
            : ""
          }>
            <Button
              icon={<FileTextOutlined />}
              onClick={onCopyToEmail}
              disabled={!isQuoteValid}
            >
              Copy to Email
            </Button>
          </Tooltip>
          <Tooltip title={
            !isQuoteValid ? 
            "Cannot export to Excel. Please ensure: \n• At least one location is added\n• Each location has at least one product\n• All required fields are filled in for each product" 
            : ""
          }>
            <Button
              icon={<FileExcelOutlined />}
              onClick={handleExportExcel}
              disabled={!isQuoteValid}
            >
              Export to Excel
            </Button>
          </Tooltip>
          <Tooltip title={
            !isQuoteValid ? 
            "Cannot clone quote. Please ensure: \n• At least one location is added\n• Each location has at least one product\n• All required fields are filled in for each product" 
            : ""
          }>
            <Button 
              icon={<CopyOutlined />} 
              onClick={handleCloneQuote}
              disabled={!isQuoteValid}
            >
              Clone Quote
            </Button>
          </Tooltip>
        </>
      )}
      {showAddLocation && (
        <Button icon={<PlusOutlined />} onClick={onAddLocation}>
          Add Location
        </Button>
      )}
      <Button 
        icon={expandAll ? <ShrinkOutlined /> : <ExpandAltOutlined />} 
        onClick={onToggleExpand}
        style={{ padding: '4px 8px' }}
      >
      </Button>
    </Space>
  );
};

export default QuoteActions;
