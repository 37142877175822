import axios, { CancelToken } from 'axios';
import { API_BASE_URL } from 'utils/api/config';
import { retryOperation, REQUEST_TIMEOUT, _now, _formatDuration, getNoCacheHeaders } from 'utils/api/apiUtils';

// Quote Request Operations with aggressive cache invalidation
export const createQuoteRequest = async (data: any, cancelToken?: CancelToken) => {
  return retryOperation(async () => {
    try {
      const headers = await getNoCacheHeaders();
      const url = `${API_BASE_URL}/createQuoteRequest`;
      
      console.log('[API] Creating quote request with:', data);
      const response = await axios.post(url, data, { 
        headers,
        cancelToken,
        timeout: REQUEST_TIMEOUT
      });
      
      console.log('[API] Quote request created successfully');
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.message || error.message;
        console.error('[API] Failed to create quote request:', {
          status: error.response?.status,
          message: errorMessage,
          data: error.response?.data
        });
      }
      throw error;
    }
  });
};

export const getQuoteRequestById = async (id: string, cancelToken?: CancelToken) => {
  const startTime = _now();
  console.log(`[API] Starting getQuoteRequestById for ID: ${id}`);
  
  return retryOperation(async () => {
    try {
      const authStartTime = _now();
      const headers = await getNoCacheHeaders();
      console.log(`[API] Auth headers obtained in ${_formatDuration(authStartTime, _now())}`);

      const url = `${API_BASE_URL}/getQuoteRequestById?id=${id}`;
      console.log(`[API] Fetching quote request from: ${url}`);

      const requestStartTime = _now();
      const response = await axios.get(url, { 
        headers,
        cancelToken,
        timeout: REQUEST_TIMEOUT
      });
      console.log(`[API] Quote request fetch completed in ${_formatDuration(requestStartTime, _now())}`);
      
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('[API] getQuoteRequestById error details:', {
          status: error.response?.status,
          statusText: error.response?.statusText,
          data: error.response?.data,
          duration: _formatDuration(startTime, _now())
        });
      }
      throw error;
    }
  });
};

export const updateQuoteRequest = async (id: string, data: any, cancelToken?: CancelToken) => {
  return retryOperation(async () => {
    try {
      const headers = await getNoCacheHeaders();
      const url = `${API_BASE_URL}/updateQuoteRequest`;
      
      console.log('[API] Updating quote request:', { id, ...data });
      const response = await axios.patch(url, { id, ...data }, { 
        headers,
        cancelToken,
        timeout: REQUEST_TIMEOUT
      });
      
      console.log('[API] Quote request updated successfully');
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.message || error.message;
        console.error('[API] Failed to update quote request:', {
          status: error.response?.status,
          message: errorMessage,
          data: error.response?.data
        });
      }
      throw error;
    }
  });
};

export const listQuoteRequests = async (stages: number[], cancelToken?: CancelToken) => {
  return retryOperation(async () => {
    try {
      const headers = await getNoCacheHeaders();
      const stagesQuery = stages.map(stage => `foxy_quotestage eq ${stage}`).join(' or ');
      const url = `${API_BASE_URL}/listQuoteRequests?stages=${encodeURIComponent(stagesQuery)}`;
      const response = await axios.get(url, { 
        headers,
        cancelToken,
        timeout: REQUEST_TIMEOUT
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('[API] Failed to list quote requests:', error.response?.data);
      }
      throw error;
    }
  });
};
