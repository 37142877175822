import { 
  Layout, 
  Space, 
  Row, 
  Col, 
  Input, 
  Typography, 
  Alert, 
  Spin,
  Tabs 
} from 'antd';

const { Content } = Layout;
const { Text } = Typography;

// Export commonly used components directly
export {
  Layout,
  Content,
  Space,
  Row,
  Col,
  Input,
  Text,
  Alert,
  Spin,
  Tabs
};
