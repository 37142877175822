import axios from 'axios';

// Performance measurement utilities
export const _now = () => performance.now();
export const _formatDuration = (start: number, end: number) => `${(end - start).toFixed(2)}ms`;

// Constants
export const MAX_RETRIES = 3;
export const RETRY_DELAY = 1000;
export const REQUEST_TIMEOUT = 15000;

const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

// Cache invalidation tracking
interface CacheInvalidationTracker {
  [key: string]: number; // timestamp of last invalidation
}

const cacheInvalidationTracker: CacheInvalidationTracker = {};

// Function to mark a cache key as invalid
export const invalidateCache = (cacheKey: string) => {
  console.log(`[Cache] Invalidating cache for key: ${cacheKey}`);
  cacheInvalidationTracker[cacheKey] = Date.now();
};

// Function to check if cache is valid
export const isCacheValid = (cacheKey: string, timestamp: number): boolean => {
  const lastInvalidation = cacheInvalidationTracker[cacheKey];
  if (!lastInvalidation) return true;
  return timestamp > lastInvalidation;
};

export const retryOperation = async <T>(
  operation: () => Promise<T>,
  retries: number = MAX_RETRIES,
  delay: number = RETRY_DELAY,
  retryCondition?: (error: any) => boolean
): Promise<T> => {
  try {
    return await operation();
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('[API] Request cancelled');
      throw error;
    }
    
    // Only retry on network errors or 5xx server errors
    const shouldRetry = (error: any) => {
      if (retryCondition && !retryCondition(error)) return false;
      if (axios.isAxiosError(error)) {
        const status = error.response?.status;
        return !status || status >= 500;
      }
      return true;
    };
    
    if (retries > 0 && shouldRetry(error)) {
      console.log(`[API] Retrying operation, ${retries} attempts remaining`);
      await wait(delay);
      return retryOperation(operation, retries - 1, delay, retryCondition);
    }
    throw error;
  }
};

// Header types with index signature to allow any string key
export interface ApiHeaders {
  Authorization: string;
  'Content-Type': string;
  'Cache-Control'?: string;
  'Pragma'?: string;
  'Expires'?: string;
  'OData-MaxVersion'?: string;
  [key: string]: string | undefined;  // Add index signature
}

export const getNoCacheHeaders = async (): Promise<ApiHeaders> => {
  const headers = await import('./config').then(m => m.getAuthHeaders());
  return {
    ...headers,
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Pragma': 'no-cache',
    'Expires': '0'
  };
};

// Error handling utility
export const handleApiError = (error: unknown, context: string): never => {
  if (axios.isAxiosError(error)) {
    console.error(`[API] ${context} error:`, {
      status: error.response?.status,
      statusText: error.response?.statusText,
      data: error.response?.data,
      message: error.message
    });
  } else {
    console.error(`[API] ${context} unknown error:`, error);
  }
  throw error;
};
