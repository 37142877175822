import axios from 'axios';
import { API_BASE_URL } from './config';
import { retryOperation, REQUEST_TIMEOUT, _now, _formatDuration, handleApiError, getNoCacheHeaders } from './apiUtils';
import { invalidateLineItemCache } from './quoteApi';

interface QuoteLineItemData {
  _foxy_foxyquoterequestlocation_value: string;
  _foxy_product_value: string;
  foxy_quantity?: number;
  foxy_each?: number;
  foxy_mrr?: number;
  foxy_linetcv?: number;
  foxy_term?: number;
  foxy_revenuetype?: number;
  foxy_renewaltype?: string;
  foxy_renewaldate?: string;
  foxy_existingqty?: number;
  foxy_existingmrr?: number;
}

export const listQuoteLineItemByRow = async (locationId: string) => {
  const _startTime = _now();
  console.log(`[API] Starting listQuoteLineItemByRow for location ID: ${locationId}`);

  return retryOperation(async () => {
    try {
      const headers = await getNoCacheHeaders();
      const url = `${API_BASE_URL}/listQuoteLineItemByRow?id=${locationId}`;
      
      console.log(`[API] Fetching quote line items from: ${url}`);
      const response = await axios.get(url, { 
        headers,
        timeout: REQUEST_TIMEOUT
      });
      
      console.log(`[API] Quote line items fetch completed in ${_formatDuration(_startTime, _now())}`);
      return response.data;
    } catch (error) {
      return handleApiError(error, 'listQuoteLineItemByRow');
    }
  });
};

export const createQuoteLineItem = async (data: QuoteLineItemData) => {
  const _startTime = _now();
  console.log('[API] Starting createQuoteLineItem');

  return retryOperation(async () => {
    try {
      const headers = await getNoCacheHeaders();
      const url = `${API_BASE_URL}/createQuoteLineItem`;
      
      console.log('[API] Creating quote line item:', data);
      const response = await axios.post(url, data, { 
        headers,
        timeout: REQUEST_TIMEOUT
      });
      
      // Immediately invalidate cache after successful creation
      invalidateLineItemCache(data._foxy_foxyquoterequestlocation_value);
      
      console.log(`[API] Quote line item created successfully in ${_formatDuration(_startTime, _now())}`);
      return response.data;
    } catch (error) {
      return handleApiError(error, 'createQuoteLineItem');
    }
  });
};

export const updateQuoteLineItem = async (data: { id: string; [key: string]: any }) => {
  const _startTime = _now();
  console.log('[API] Starting updateQuoteLineItem');

  return retryOperation(async () => {
    try {
      const headers = await getNoCacheHeaders();
      const url = `${API_BASE_URL}/updateQuoteLineItem`;
      
      console.log('[API] Updating quote line item:', data);
      const response = await axios.patch(url, data, { 
        headers,
        timeout: REQUEST_TIMEOUT
      });
      
      // Immediately invalidate cache after successful update
      if (data._foxy_foxyquoterequestlocation_value) {
        invalidateLineItemCache(data._foxy_foxyquoterequestlocation_value);
      }
      
      console.log(`[API] Quote line item updated successfully in ${_formatDuration(_startTime, _now())}`);
      return response.data;
    } catch (error) {
      return handleApiError(error, 'updateQuoteLineItem');
    }
  });
};

export const deleteQuoteLineItem = async (id: string): Promise<void> => {
  const _startTime = _now();
  console.log('[API] Starting deleteQuoteLineItem');

  return retryOperation(async () => {
    try {
      const headers = await getNoCacheHeaders();
      const url = `${API_BASE_URL}/deleteQuoteLineItem?id=${id}`;
      
      console.log('[API] Deleting quote line item:', id);
      await axios.delete(url, { 
        headers,
        timeout: REQUEST_TIMEOUT
      });
      
      console.log(`[API] Quote line item deleted successfully in ${_formatDuration(_startTime, _now())}`);
    } catch (error) {
      return handleApiError(error, 'deleteQuoteLineItem');
    }
  });
};
