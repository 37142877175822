import axios from 'axios';
import { API_BASE_URL } from './config';
import { 
  retryOperation, 
  REQUEST_TIMEOUT, 
  invalidateCache, 
  handleApiError,
  getNoCacheHeaders,
  _now,
  _formatDuration 
} from './apiUtils';
import { Product } from '../../types';

// Re-export quote request operations
export {
  createQuoteRequest,
  getQuoteRequestById,
  updateQuoteRequest,
  listQuoteRequests
} from './quoteRequestApi';

// Re-export quote line item operations
export {
  listQuoteLineItemByRow,
  createQuoteLineItem,
  updateQuoteLineItem,
  deleteQuoteLineItem
} from './quoteLineItemApi';

// Re-export quote location operations
export {
  listQuoteLocationRows,
  createFoxyQuoteRequestLocation,
  deleteQuoteLocation
} from './quoteLocationApi';

// Cache keys
export const CACHE_KEYS = {
  PRODUCTS: 'products',
  QUOTE_REQUEST: (id: string) => `quote_request_${id}`,
  QUOTE_LINE_ITEMS: (locationId: string) => `quote_line_items_${locationId}`,
  QUOTE_LOCATIONS: (id: string) => `quote_locations_${id}`
};

// Product operations
export const fetchProducts = async (filter?: string): Promise<Product[]> => {
  const _startTime = _now();
  console.log('[API] Starting fetchProducts');

  return retryOperation(async () => {
    try {
      const headers = await getNoCacheHeaders();
      const url = `${API_BASE_URL}/listProductByRow${filter ? `?$filter=${encodeURIComponent(filter)}` : ''}`;
      
      console.log('[API] Fetching products');
      const response = await axios.get(url, { 
        headers,
        timeout: REQUEST_TIMEOUT
      });
      
      console.log(`[API] Products fetched successfully in ${_formatDuration(_startTime, _now())}`);
      return response.data.value || [];
    } catch (error) {
      return handleApiError(error, 'fetchProducts');
    }
  });
};

// Wireless operations
export const fetchWirelessPlans = async (): Promise<Product[]> => {
  const _startTime = _now();
  console.log('[API] Starting fetchWirelessPlans');

  return retryOperation(async () => {
    try {
      const headers = await getNoCacheHeaders();
      const url = `${API_BASE_URL}/listWirelessPlansByRow`;
      
      console.log('[API] Fetching wireless plans');
      const response = await axios.get(url, { 
        headers,
        timeout: REQUEST_TIMEOUT
      });
      
      console.log(`[API] Wireless plans fetched successfully in ${_formatDuration(_startTime, _now())}`);
      return response.data.value || [];
    } catch (error) {
      return handleApiError(error, 'fetchWirelessPlans');
    }
  });
};

export const fetchWirelessQuoteLineItems = async (id: string) => {
  return retryOperation(async () => {
    try {
      const headers = await getNoCacheHeaders();
      const response = await axios.get(`${API_BASE_URL}/listWirelessQuoteLineItems?id=${id}`, {
        headers,
        timeout: REQUEST_TIMEOUT
      });
      return response.data.value;
    } catch (error) {
      console.error('Error fetching wireless quote line items:', error);
      throw error;
    }
  });
};

// Cache invalidation helpers
export const invalidateQuoteCache = (quoteId: string) => {
  console.log('[Cache] Invalidating quote-related caches');
  invalidateCache(CACHE_KEYS.QUOTE_REQUEST(quoteId));
  // When a quote is updated, we should also invalidate its locations
  invalidateCache(CACHE_KEYS.QUOTE_LOCATIONS(quoteId));
};

export const invalidateLocationCache = (quoteId: string, locationId: string) => {
  console.log('[Cache] Invalidating location-related caches');
  invalidateCache(CACHE_KEYS.QUOTE_LOCATIONS(quoteId));
  invalidateCache(CACHE_KEYS.QUOTE_LINE_ITEMS(locationId));
};

// This function can be called after any line item operation (create, update, delete)
export const invalidateLineItemCache = (locationId: string) => {
  console.log('[Cache] Invalidating line item cache');
  invalidateCache(CACHE_KEYS.QUOTE_LINE_ITEMS(locationId));
};
