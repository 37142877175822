import { useState, useEffect, useCallback, SetStateAction } from 'react';
import { message } from 'antd';
import {
  QuoteLocation,
  QuoteLineItem,
  QuoteDataState,
  QuoteDataReturn
} from '../types';
import {
  getQuoteRequestById,
  listQuoteLocationRows,
  listQuoteLineItemByRow
} from '../utils/api';

// Add performance measurement utilities
const _now = () => performance.now();
const _formatDuration = (start: number, end: number) => `${(end - start).toFixed(2)}ms`;

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second between retries

export const useQuoteData = (id: string | undefined): QuoteDataReturn => {
  const [_lineItems, _setLineItems] = useState<{ [key: string]: QuoteLineItem[] }>({});
  const [state, setState] = useState<QuoteDataState>({
    accountName: '',
    accountId: '',
    quoteId: '',
    locations: [],
    lineItems: {},
    error: null,
    loading: true,
    owninguser: undefined,
    rawQuoteData: {
      lineItems: {},
      locations: [],
      quoteRequest: {}
    }
  });

  const setLineItems = useCallback((value: SetStateAction<{ [key: string]: QuoteLineItem[] }>) => {
    _setLineItems(prev => {
      const newLineItems = typeof value === 'function' ? value(prev) : value;
      setState(prevState => ({
        ...prevState,
        lineItems: newLineItems,
        rawQuoteData: {
          ...prevState.rawQuoteData,
          lineItems: newLineItems
        }
      }));
      return newLineItems;
    });
  }, []); // No dependencies needed since we use functional updates

  const fetchWithRetry = useCallback(async <T>(
    operation: () => Promise<T>,
    retries = MAX_RETRIES
  ): Promise<T> => {
    try {
      return await operation();
    } catch (error) {
      if (retries > 0) {
        await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
        return fetchWithRetry(operation, retries - 1);
      }
      throw error;
    }
  }, []);

  const fetchData = useCallback(async () => {
    const _startTime = _now();
    console.log(`[QuoteData] Starting data fetch for quote ID: ${id}`);

    if (!id || !/^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i.test(id)) {
      console.error(`[QuoteData] Invalid quote ID provided: ${id}`);
      setState(prev => ({ ...prev, error: 'Invalid quote ID. Please provide a valid GUID.', loading: false }));
      return;
    }

    try {
      // Fetch quote request data
      console.log(`[QuoteData] Fetching quote request data for ID: ${id}`);
      const quoteRequestStartTime = _now();

      // Fetch quote request and locations in parallel
      const [quoteRequestData, locationsResponse] = await Promise.all([
        fetchWithRetry(() => getQuoteRequestById(id)),
        fetchWithRetry(() => listQuoteLocationRows(id))
      ]);

      console.log(`[QuoteData] Quote request data fetch completed in ${_formatDuration(quoteRequestStartTime, _now())}`);

      if (!quoteRequestData?.foxy_Account) {
        console.error('[QuoteData] Quote data is incomplete - missing Account information');
        setState(prev => ({
          ...prev,
          error: 'Quote data is incomplete or malformed',
          loading: false
        }));
        return;
      }

      // Process locations
      const locations = locationsResponse.value || [];
      console.log(`[QuoteData] Found ${locations.length} locations`);

      // Fetch line items for all locations in parallel
      console.log(`[QuoteData] Starting parallel line items fetch for ${locations.length} locations`);
      const lineItemsStartTime = _now();

      const lineItemsPromises = locations.map((location: QuoteLocation) =>
        fetchWithRetry(() =>
          listQuoteLineItemByRow(location.foxy_foxyquoterequestlocationid)
            .then(response => ({
              locationId: location.foxy_foxyquoterequestlocationid,
              items: response.value || []
            }))
        ).catch(error => {
          console.error(`[QuoteData] Failed to load line items for location ${location.foxy_locationid}:`, error);
          message.error(`Failed to load line items for location ${location.foxy_locationid}`);
          return {
            locationId: location.foxy_foxyquoterequestlocationid,
            items: []
          };
        })
      );

      const lineItemsResults = await Promise.all(lineItemsPromises);
      const lineItemsMap = lineItemsResults.reduce((acc, { locationId, items }) => {
        acc[locationId] = items;
        return acc;
      }, {} as { [key: string]: QuoteLineItem[] });

      console.log(`[QuoteData] All line items fetched in parallel in ${_formatDuration(lineItemsStartTime, _now())}`);

      // Update state
      const updateStartTime = _now();
      const newState = {
        accountName: quoteRequestData.foxy_Account?.name || 'Unknown Account',
        accountId: quoteRequestData.foxy_Account?.accountid || '',
        quoteId: quoteRequestData.foxy_quoteid || '',
        locations,
        lineItems: lineItemsMap,
        error: null,
        loading: false,
        owninguser: quoteRequestData.owninguser,
        rawQuoteData: {
          lineItems: lineItemsMap,
          locations,
          quoteRequest: quoteRequestData
        }
      };

      setLineItems(lineItemsMap);
      setState(newState);
      console.log(`[QuoteData] State update completed in ${_formatDuration(updateStartTime, _now())}`);

    } catch (error) {
      console.error('[QuoteData] Error in fetchData:', error);
      setState(prev => ({
        ...prev,
        error: 'Failed to load quote data',
        loading: false
      }));
    }
  }, [id, fetchWithRetry, setLineItems]);

  const refetchLocations = useCallback(async () => {
    if (!id) return;

    const _startTime = _now();
    console.log(`[QuoteData] Starting location refetch for quote ID: ${id}`);

    try {
      const locationsStartTime = _now();
      const locationsResponse = await fetchWithRetry(() => listQuoteLocationRows(id));
      const locations = locationsResponse.value || [];
      console.log(`[QuoteData] Locations refetch completed in ${_formatDuration(locationsStartTime, _now())}`);

      const lineItemsStartTime = _now();
      const lineItemsMap: { [key: string]: QuoteLineItem[] } = {};

      // Fetch all line items in parallel with retry logic
      const lineItemsPromises = locations.map((location: QuoteLocation) =>
        fetchWithRetry(() =>
          listQuoteLineItemByRow(location.foxy_foxyquoterequestlocationid)
            .then(response => ({
              locationId: location.foxy_foxyquoterequestlocationid,
              items: response.value || []
            }))
        ).catch(error => {
          console.error(`[QuoteData] Failed to refetch line items for location ${location.foxy_locationid}:`, error);
          message.error(`Failed to load line items for location ${location.foxy_locationid}`);
          return {
            locationId: location.foxy_foxyquoterequestlocationid,
            items: []
          };
        })
      );

      const lineItemsResults = await Promise.all(lineItemsPromises);
      lineItemsResults.forEach(({ locationId, items }) => {
        lineItemsMap[locationId] = items;
      });

      console.log(`[QuoteData] All line items refetched in ${_formatDuration(lineItemsStartTime, _now())}`);

      const updateStartTime = _now();
      const newState = {
        ...state,
        locations,
        lineItems: lineItemsMap,
        rawQuoteData: {
          ...state.rawQuoteData,
          locations,
          lineItems: lineItemsMap
        }
      };

      setLineItems(lineItemsMap);
      setState(newState);

      console.log(`[QuoteData] Refetch state update completed in ${_formatDuration(updateStartTime, _now())}`);

    } catch (error) {
      console.error('[QuoteData] Error in refetchLocations:', error);
      message.error('Failed to refresh locations');
    }
  }, [id, state, fetchWithRetry, setLineItems]);

  const refetch = useCallback(async () => {
    if (!id) return;

    const _startTime = _now();
    console.log(`[QuoteData] Starting quote data refetch for ID: ${id}`);

    setState(prev => ({ ...prev, loading: true }));
    try {
      const quoteStartTime = _now();
      const quoteData = await fetchWithRetry(() => getQuoteRequestById(id));
      console.log(`[QuoteData] Quote data refetch completed in ${_formatDuration(quoteStartTime, _now())}`);

      const updateStartTime = _now();
      const newState = {
        ...state,
        loading: false,
        accountName: quoteData.foxy_Account?.name || 'Unknown Account',
        accountId: quoteData.foxy_Account?.accountid || '',
        quoteId: quoteData.foxy_quoteid || '',
        owninguser: quoteData.owninguser,
        rawQuoteData: {
          ...state.rawQuoteData,
          quoteRequest: quoteData
        }
      };

      setState(newState);
      console.log(`[QuoteData] Refetch state update completed in ${_formatDuration(updateStartTime, _now())}`);

      // After updating quote data, also refresh locations and line items
      await refetchLocations();

    } catch (error) {
      console.error('[QuoteData] Error in refetch:', error);
      setState(prev => ({
        ...prev,
        error: 'Failed to refetch quote data',
        loading: false
      }));
      message.error('Failed to refresh quote data');
    }
  }, [id, state, refetchLocations, fetchWithRetry]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (!id) {
    console.log('[QuoteData] No ID provided, returning empty state');
    return {
      accountName: '',
      accountId: '',
      quoteId: '',
      locations: [],
      lineItems: {},
      error: 'No ID provided',
      loading: false,
      owninguser: undefined,
      rawQuoteData: {
        lineItems: {},
        locations: [],
        quoteRequest: {}
      },
      refetchLocations,
      setLineItems,
      refetch
    };
  }

  return {
    ...state,
    refetchLocations,
    setLineItems,
    refetch
  };
};

export default useQuoteData;
