import axios from 'axios';
import { API_BASE_URL } from 'utils/api/config';
import { retryOperation, REQUEST_TIMEOUT, _now, _formatDuration, getNoCacheHeaders, handleApiError } from 'utils/api/apiUtils';

export const getAccountById = async (id: string) => {
  const _startTime = _now();
  console.log(`[API] Starting getAccountById for ID: ${id}`);

  return retryOperation(async () => {
    try {
      const headers = await getNoCacheHeaders();
      const url = `${API_BASE_URL}/getAccountById?id=${id}`;
      
      const response = await axios.get(url, { 
        headers,
        timeout: REQUEST_TIMEOUT
      });
      
      console.log(`[API] Account fetch completed in ${_formatDuration(_startTime, _now())}`);
      return response.data;
    } catch (error) {
      return handleApiError(error, 'getAccountById');
    }
  });
};

export const listAccountLocationRows = async (accountId: string) => {
  const _startTime = _now();
  console.log(`[API] Starting listAccountLocationRows for account ID: ${accountId}`);

  return retryOperation(async () => {
    try {
      const headers = await getNoCacheHeaders();
      const url = `${API_BASE_URL}/listAccountLocationRows?accountId=${accountId}`;
      
      const response = await axios.get(url, { 
        headers,
        timeout: REQUEST_TIMEOUT
      });
      
      console.log(`[API] Account locations fetch completed in ${_formatDuration(_startTime, _now())}`);
      return response.data;
    } catch (error) {
      return handleApiError(error, 'listAccountLocationRows');
    }
  });
};

export const listOpportunityRows = async (accountId: string) => {
  const _startTime = _now();
  console.log(`[API] Starting listOpportunityRows for account ID: ${accountId}`);

  return retryOperation(async () => {
    try {
      const headers = await getNoCacheHeaders();
      const url = `${API_BASE_URL}/listOpportunityRows?accountId=${accountId}`;
      
      const response = await axios.get(url, { 
        headers,
        timeout: REQUEST_TIMEOUT
      });
      
      console.log(`[API] Opportunities fetch completed in ${_formatDuration(_startTime, _now())}`);
      return response.data;
    } catch (error) {
      return handleApiError(error, 'listOpportunityRows');
    }
  });
};
