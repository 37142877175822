import axios from 'axios';
import { API_BASE_URL } from './config';
import { retryOperation, REQUEST_TIMEOUT, _now, _formatDuration, getNoCacheHeaders, handleApiError } from './apiUtils';
import { invalidateLocationCache } from './quoteApi';

export const listQuoteLocationRows = async (id: string) => {
  const _startTime = _now();
  console.log(`[API] Starting listQuoteLocationRows for ID: ${id}`);

  return retryOperation(async () => {
    try {
      const authStartTime = _now();
      const headers = await getNoCacheHeaders();
      console.log(`[API] Auth headers obtained in ${_formatDuration(authStartTime, _now())}`);

      const url = `${API_BASE_URL}/listQuoteLocationRows?id=${id}`;
      console.log(`[API] Fetching quote locations from: ${url}`);

      const requestStartTime = _now();
      const response = await axios.get(url, { 
        headers,
        timeout: REQUEST_TIMEOUT
      });
      console.log(`[API] Quote locations fetch completed in ${_formatDuration(requestStartTime, _now())}`);
      
      return response.data;
    } catch (error) {
      return handleApiError(error, 'listQuoteLocationRows');
    }
  });
};

export const createFoxyQuoteRequestLocation = async (
  buildingId: string,
  quoteRequestId: string,
  accountLocationId: string
) => {
  const _startTime = _now();
  console.log('[API] Starting createFoxyQuoteRequestLocation');

  return retryOperation(async () => {
    try {
      const headers = await getNoCacheHeaders();
      const url = `${API_BASE_URL}/createFoxyQuoteRequestLocation`;
      
      const requestBody = {
        "_foxy_building_value": buildingId,
        "_foxy_quoterequest_value": quoteRequestId,
        "_foxy_accountlocation_value": accountLocationId
      };
      
      console.log('[API] Creating quote location:', requestBody);
      const response = await axios.post(url, requestBody, { 
        headers,
        timeout: REQUEST_TIMEOUT
      });

      // Invalidate cache after successful creation
      invalidateLocationCache(quoteRequestId, response.data.foxy_foxyquoterequestlocationid);
      
      console.log(`[API] Quote location created successfully in ${_formatDuration(_startTime, _now())}`);
      return response.data;
    } catch (error) {
      return handleApiError(error, 'createFoxyQuoteRequestLocation');
    }
  });
};

export const deleteQuoteLocation = async (locationId: string, quoteRequestId?: string): Promise<void> => {
  const _startTime = _now();
  console.log('[API] Starting deleteQuoteLocation');

  return retryOperation(async () => {
    try {
      const headers = await getNoCacheHeaders();
      const url = `${API_BASE_URL}/deleteQuoteLocation?id=${locationId}`;
      
      console.log('[API] Deleting quote location:', locationId);
      await axios.delete(url, { 
        headers,
        timeout: REQUEST_TIMEOUT
      });
      
      // If we have the quoteRequestId, invalidate related caches
      if (quoteRequestId) {
        invalidateLocationCache(quoteRequestId, locationId);
      }
      
      console.log(`[API] Quote location deleted successfully in ${_formatDuration(_startTime, _now())}`);
    } catch (error) {
      return handleApiError(error, 'deleteQuoteLocation');
    }
  });
};
