import React, { useState, useEffect } from 'react';
import { Table, Typography, Row, Col, Button, Tooltip, Form, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { fetchWirelessPlans, fetchWirelessQuoteLineItems } from '../../../utils/api';

const { Title } = Typography;

interface WirelessQuoteMessageProps {
  quoteId: string;
}

const WirelessQuoteMessage: React.FC<WirelessQuoteMessageProps> = ({ quoteId }) => {
  const [editingKey, setEditingKey] = useState<string>('');
  const [wirelessPlans, setWirelessPlans] = useState<any[]>([]);
  const [lineItems, setLineItems] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchLineItems = async () => {
      try {
        const items = await fetchWirelessQuoteLineItems(quoteId);
        setLineItems(items.map((item: any) => ({
          key: item.foxy_wirelessquoterequestlineitemid,
          product: item.foxy_Plan?.foxy_name || '',
          quantity: item.foxy_quantity,
          discount: 0
        })));
      } catch (error) {
        console.error('Error fetching line items:', error);
      }
    };
    
    if (quoteId) {
      fetchLineItems();
    }
  }, [quoteId]);

  const fetchWirelessPlansData = async () => {
    setLoading(true);
    try {
      const plans = await fetchWirelessPlans();
      setWirelessPlans(plans);
      return plans;
    } catch (error) {
      console.error('Error fetching wireless plans:', error);
    } finally {
      setLoading(false);
    }
  };

  const isEditing = (record: any) => record.key === editingKey;

  const handleAddPlan = () => {
    const newData = {
      key: `plan-${lineItems.length + 1}`,
      product: '',
      quantity: 1,
      discount: 0
    };
    setLineItems([...lineItems, newData]);
    form.setFieldsValue(newData);
    setEditingKey(newData.key);
  };

  const columns = [
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        return editable ? (
          <Form.Item
            name="product"
            style={{ margin: 0 }}
            rules={[{ required: true, message: 'Product is required' }]}
          >
            <Select
              showSearch
              placeholder="Select a wireless plan"
              optionFilterProp="label"
              loading={loading}
              style={{ width: '300px' }}
              onFocus={() => {
                if (wirelessPlans.length === 0) {
                  fetchWirelessPlansData();
                }
              }}
              options={wirelessPlans.map(plan => ({
                value: plan.foxy_productid,
                label: plan.foxy_name
              }))}
              filterOption={(input, option) => {
                if (!option?.label) return false;
                return option.label.toLowerCase().includes(input.toLowerCase());
              }}
            />
          </Form.Item>
        ) : (
          record.product
        );
      }
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Discount %',
      dataIndex: 'discount',
      key: 'discount',
    }
  ];

  return (
    <div style={{ background: '#fff', borderRadius: '8px', padding: '24px' }}>
      <Row align="middle" justify="space-between" style={{ marginBottom: 16 }}>
        <Col>
          <Title level={5} style={{ margin: 0 }}>
            Wireless Cart
          </Title>
        </Col>
        <Col>
          <Tooltip title="Add Plan">
            <Button
              icon={<PlusOutlined />}
              onClick={handleAddPlan}
              type="text"
              style={{ color: '#1890ff' }}
            />
          </Tooltip>
        </Col>
      </Row>
      <Form form={form}>
        <Table
          columns={columns}
          dataSource={lineItems}
          pagination={false}
          size="large"
          className="rounded-table"
        />
      </Form>
    </div>
  );
};

export default WirelessQuoteMessage;
