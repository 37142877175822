import React from 'react';
import { Modal } from 'antd';

export interface DeleteConfirmationModalProps {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  loading?: boolean;
  title?: string;
  message?: string;
}

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
  open,
  onConfirm,
  onCancel,
  loading = false,
  title = "Confirm Deletion",
  message = "Are you sure you want to delete this line item? This action cannot be undone."
}) => (
  <Modal
    title={title}
    open={open}
    onOk={onConfirm}
    onCancel={onCancel}
    confirmLoading={loading}
    okButtonProps={{ disabled: loading }}
    cancelButtonProps={{ disabled: loading }}
  >
    <p>{message}</p>
  </Modal>
);

export default DeleteConfirmationModal;
