import React from 'react';
import { Card, Space, Typography, Badge, Collapse, Row, Col } from 'antd';
import { FixedCosts } from '../types';
import InputField from './InputField';

const { Text } = Typography;
const { Panel } = Collapse;

interface FixedCostsPanelProps {
  title: string;
  fixedCosts: FixedCosts;
  onFixedCostChange: (field: keyof FixedCosts, e: React.ChangeEvent<HTMLInputElement>) => void;
  showCopyButtons?: boolean;
  onCopyToNew?: (field: keyof FixedCosts) => void;
  activeKey?: string[];
  onCollapse?: (key: string[]) => void;
}

const FixedCostsPanel: React.FC<FixedCostsPanelProps> = ({ 
  title,
  fixedCosts,
  onFixedCostChange,
  showCopyButtons = false,
  onCopyToNew,
  activeKey,
  onCollapse
}) => {
  const activeCount = Object.values(fixedCosts).filter(value => Number(value) > 0).length;
  
  return (
    <Collapse 
      ghost 
      activeKey={activeKey}
      onChange={onCollapse}
    >
      <Panel 
        header={
          <Space>
            <Text strong>{title}</Text>
            {activeCount > 0 && (
              <Badge 
                count={activeCount} 
                style={{ 
                  backgroundColor: '#722ed1',
                  marginLeft: '8px' 
                }} 
              />
            )}
          </Space>
        } 
        key="1"
      >
        <Card size="small">
          <Row gutter={16}>
            <Col span={12}>
              <InputField
                label="Roaming"
                value={fixedCosts.roaming}
                onChange={(e) => onFixedCostChange('roaming', e)}
                step="0.01"
                prefix="$"
                showCopyButton={showCopyButtons}
                onCopyToNew={onCopyToNew ? () => onCopyToNew('roaming') : undefined}
              />
            </Col>
            <Col span={12}>
              <InputField
                label="Add-Ons"
                value={fixedCosts.addOns}
                onChange={(e) => onFixedCostChange('addOns', e)}
                step="0.01"
                prefix="$"
                showCopyButton={showCopyButtons}
                onCopyToNew={onCopyToNew ? () => onCopyToNew('addOns') : undefined}
              />
            </Col>
          </Row>
        </Card>
      </Panel>
    </Collapse>
  );
};

export default React.memo(FixedCostsPanel);
