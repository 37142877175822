import React, { useState, useEffect, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { message, Alert } from 'antd';
import { useQuoteData } from '../../hooks/useQuoteData';
import { useModal } from '../../hooks/useModal';
import { deleteQuoteLocation, deleteQuoteLineItem, updateQuoteRequest } from '../../utils/api';
import { checkUserAccess, UserAccessLevel } from '../../auth/authService';
import Header from '../../components/shared/Header';
import AddLocationModal from './components/modals/AddLocationModal';
import { QuotePageProps, RawQuoteData } from '../../types';
import {
  Layout,
  Content,
  Tabs,
  Spin
} from '../../components/shared/AntComponents';
import {
  MainTab,
  DetailsTab,
  RawDataTab,
  CompensationTab
} from './components';

// Add this new component
const _RawDataTabs: React.FC<{ data: any }> = ({ data }) => {
  const items = [
    {
      key: 'lineItems',
      label: 'Line Items',
      children: <RawDataTab data={data.lineItems} />
    },
    {
      key: 'locations',
      label: 'Locations',
      children: <RawDataTab data={data.locations} />
    },
    {
      key: 'quoteRequest',
      label: 'Quote Request',
      children: <RawDataTab data={data.quoteRequest} />
    }
  ];

  return <Tabs items={items} />;
};

const QuotePage: React.FC<QuotePageProps> = ({ setQuoteRequestId }) => {
  const { id } = useParams<{ id: string }>();
  const { 
    accountName, 
    quoteId, 
    locations, 
    lineItems, 
    error, 
    loading, 
    owninguser, 
    accountId, 
    refetchLocations,
    rawQuoteData,
    refetch 
  } = useQuoteData(id);
  const { isVisible, show, hide } = useModal();
  const [expandAll, setExpandAll] = useState(true);
  const [_rawData, setRawData] = useState<RawQuoteData>({
    lineItems: {},
    locations: [],
    quoteRequest: {}
  });
  const [isEditingSubject, setIsEditingSubject] = useState(false);
  const [editSubjectValue, setEditSubjectValue] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [userAccessLevel, setUserAccessLevel] = useState<UserAccessLevel>('none');

  useEffect(() => {
    const checkAccess = async () => {
      const access = await checkUserAccess();
      setUserAccessLevel(access);
      setIsAdmin(access === 'admin');
    };
    checkAccess();
  }, []);

  useEffect(() => {
    setQuoteRequestId(quoteId);
  }, [quoteId, setQuoteRequestId]);

  useEffect(() => {
    if (rawQuoteData) {
      setRawData(rawQuoteData);
    }
  }, [rawQuoteData]);

  useEffect(() => {
    const quoteDisplayId = rawQuoteData?.quoteRequest?.foxy_quoteid;
    document.title = quoteDisplayId || 'Foxy CPQ';
    
    // Cleanup - reset title when component unmounts
    return () => {
      document.title = 'Foxy CPQ';
    };
  }, [rawQuoteData?.quoteRequest?.foxy_quoteid]);

  const toggleExpandAll = () => {
    setExpandAll(!expandAll);
  };

  const handleAddLocationSuccess = () => {
    hide();
    refetchLocations();
  };

  const handleDeleteLocation = async (locationId: string) => {
    try {
      const locationLineItems = lineItems[locationId] || [];
      for (const item of locationLineItems) {
        await deleteQuoteLineItem(item.foxy_foxyquoterequestlineitemid);
      }
      await deleteQuoteLocation(locationId, id);
      message.success('Location deleted successfully');
      refetchLocations();
    } catch (error) {
      message.error('Failed to delete location');
      console.error('Delete location error:', error);
    }
  };

  const handleUpdateLineItem = async (locationId: string, updatedItem: any) => {
    try {
      await refetchLocations();
      const isNewItem = updatedItem?.foxy_foxyquoterequestlineitemid?.startsWith('temp-') || false;
      message.success(`Line item ${isNewItem ? 'created' : 'updated'} successfully`);
    } catch (error) {
      console.error('Error updating line item:', error);
      message.error('Failed to update line item');
    }
  };

  const handleDeleteLineItem = async (_locationId: string, _itemId: string) => {
    await refetchLocations();
  };

  const handleAddLineItem = async (_locationId: string, _newItem: any) => {
    await refetchLocations();
  };

  if (loading) {
    return (
      <Layout style={{ minHeight: '100vh', padding: '12px' }}>
        <Spin size="large" />
      </Layout>
    );
  }

  const tabItems = [
    {
      key: '1',
      label: rawQuoteData.quoteRequest?.foxy_quoteid || 'New Quote',
      children: (
        <MainTab
          accountName={accountName}
          rawQuoteData={rawQuoteData}
          locations={locations}
          lineItems={lineItems}
          error={error}
          expandAll={expandAll}
          isEditingSubject={isEditingSubject}
          editSubjectValue={editSubjectValue}
          owninguser={owninguser}
          id={id || ''}
          accountId={accountId}
          show={show}
          toggleExpandAll={toggleExpandAll}
          refetch={refetch}
          setEditSubjectValue={setEditSubjectValue}
          setIsEditingSubject={setIsEditingSubject}
          handleAddLineItem={handleAddLineItem}
          handleDeleteLocation={handleDeleteLocation}
          handleUpdateLineItem={handleUpdateLineItem}
          handleDeleteLineItem={handleDeleteLineItem}
          updateQuoteRequest={updateQuoteRequest}
          userAccessLevel={userAccessLevel}
        />
      ),
    },
    {
      key: '2',
      label: 'Details',
      children: (
        <DetailsTab 
          opportunity={rawQuoteData.quoteRequest?.foxy_Opportunity}
          account={rawQuoteData.quoteRequest?.foxy_Account}
          subject={rawQuoteData.quoteRequest?.foxy_subject}
          opportunityId={rawQuoteData.quoteRequest?._foxy_opportunity_value}
          quoteId={id}
          foxyQuoteId={rawQuoteData.quoteRequest?.foxy_quoteid}
          submittedOn={rawQuoteData.quoteRequest?.foxyflow_submittedon}
        />
      ),
    },
    {
      key: '4',
      label: 'Revenue',
      children: (
        <Suspense fallback={<Spin size="large" />}>
          <CompensationTab lineItems={lineItems} locations={locations} />
        </Suspense>
      ),
    },
    {
      key: '5',
      label: 'Developer',
      children: (
        <Suspense fallback={<Spin size="large" />}>
          <RawDataTab data={rawQuoteData} />
        </Suspense>
      ),
    }
  ];

  // Show only Quote and Details tabs for non-admin users
  // Show all tabs (including Compensation and Developer Tools) for admin users
  const visibleTabs = isAdmin ? tabItems : [tabItems[0], tabItems[1]];

  const showBaseCustomerWarning = rawQuoteData?.quoteRequest?.foxy_Account?.foxy_basecustomer !== 612100001;

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header />
      {showBaseCustomerWarning && (
        <Alert
          message="Warning: This account is not owned by Infusion. Please update if needed, otherwise proceed with caution."
          type="warning"
          showIcon
          banner
          style={{ margin: 0 }}
        />
      )}
      <Content style={{ padding: '20px 50px' }}>
        <Tabs items={visibleTabs} />
      </Content>
      <AddLocationModal
        isVisible={isVisible}
        onOk={handleAddLocationSuccess}
        onCancel={hide}
        quoteRequestId={id || ''}
        accountId={accountId}
        onRefresh={refetchLocations}
        existingLocations={locations}
      />
    </Layout>
  );
};

export default QuotePage;
