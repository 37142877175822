import React from 'react';
import { Card, Row, Col, Statistic, Divider, Typography } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { Plan, FixedCosts } from '../types';

const { Title } = Typography;

interface SummaryProps {
  currentTotal: number;
  newTotal: number;
  currentPlans: Plan[];
  newPlans: Plan[];
  currentFixedCosts: FixedCosts;
  newFixedCosts: FixedCosts;
}

const Summary: React.FC<SummaryProps> = ({ 
  currentTotal, 
  newTotal, 
  currentPlans, 
  newPlans,
  currentFixedCosts,
  newFixedCosts
}) => {
  const difference = currentTotal - newTotal;
  const isIncrease = difference < 0;

  const calculateTotalData = (plans: Plan[]): number => {
    return plans.reduce((sum, plan) => 
      sum + (Number(plan.quantity || 0) * Number(plan.data || 0)), 0
    );
  };

  const calculatePlanTotal = (plans: Plan[]): number => {
    return plans.reduce((sum, plan) => 
      sum + (Number(plan.quantity || 0) * Number(plan.price || 0)), 0
    );
  };

  const calculateFixedTotal = (costs: FixedCosts): number => {
    return Number(costs.roaming || 0) + 
           Number(costs.addOns || 0);
  };

  const currentData = calculateTotalData(currentPlans);
  const newData = calculateTotalData(newPlans);
  const currentPlanTotal = calculatePlanTotal(currentPlans);
  const newPlanTotal = calculatePlanTotal(newPlans);
  const currentFixedTotal = calculateFixedTotal(currentFixedCosts);
  const newFixedTotal = calculateFixedTotal(newFixedCosts);

  return (
    <div style={{ marginTop: '24px' }}>
      <Title level={4} style={{ marginBottom: '24px' }}>Summary</Title>
      
      <Row gutter={[24, 24]}>
        {/* Current Side */}
        <Col xs={24} sm={12}>
          <Card 
            title={<Title level={5} style={{ margin: 0 }}>Current</Title>}
            style={{ height: '100%' }}
          >
            <Statistic
              title="Plan Total"
              value={currentPlanTotal}
              precision={2}
              prefix="$"
            />
            <Statistic
              title="Usage Based Costs"
              value={currentFixedTotal}
              precision={2}
              prefix="$"
              style={{ marginTop: '16px' }}
            />
            <Divider style={{ margin: '16px 0' }} />
            <Statistic
              title="Total"
              value={currentTotal}
              precision={2}
              prefix="$"
              valueStyle={{ 
                fontWeight: 'bold',
                fontSize: '24px'
              }}
            />
            <Divider style={{ margin: '16px 0' }} />
            <Statistic
              title="Data"
              value={currentData}
              suffix="GB"
              valueStyle={{ color: '#1890ff' }}
            />
          </Card>
        </Col>

        {/* New Side */}
        <Col xs={24} sm={12}>
          <Card 
            title={<Title level={5} style={{ margin: 0 }}>New</Title>}
            style={{ height: '100%' }}
          >
            <Statistic
              title="Plan Total"
              value={newPlanTotal}
              precision={2}
              prefix="$"
            />
            <Statistic
              title="Usage Based Costs"
              value={newFixedTotal}
              precision={2}
              prefix="$"
              style={{ marginTop: '16px' }}
            />
            <Divider style={{ margin: '16px 0' }} />
            <Statistic
              title="Total"
              value={newTotal}
              precision={2}
              prefix="$"
              valueStyle={{ 
                fontWeight: 'bold',
                fontSize: '24px'
              }}
            />
            <Divider style={{ margin: '16px 0' }} />
            <Statistic
              title="Data"
              value={newData}
              suffix="GB"
              valueStyle={{ color: '#1890ff' }}
            />
          </Card>
        </Col>

        {/* Difference Card */}
        <Col xs={24}>
          <Card style={{ textAlign: 'center' }}>
            <Statistic
              title={isIncrease ? "Monthly Increase" : "Monthly Savings"}
              value={Math.abs(difference)}
              precision={2}
              prefix={
                <span>
                  {isIncrease ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                  {' $'}
                </span>
              }
              valueStyle={{ 
                color: isIncrease ? '#cf1322' : '#3f8600',
                fontSize: '28px',
                fontWeight: 'bold'
              }}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default React.memo(Summary);
