import React from 'react';
import { Card, Button, Typography, InputNumber, AutoComplete } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { Plan } from '../types';

const { Text } = Typography;

const CURRENT_PLAN_OPTIONS = [
  'BWPv2 Pooled Metered (BYOD)',
  'BWPv2 Pooled Metered (Subsidy)',
  'SUDP Pooled Unlimited (BYOD/Financing)'
].map(value => ({ value }));

const NEW_PLAN_OPTIONS = [
  'Advantage Mobility (BYOD/Financing)',
  'Shared Unlimited Data Plan Pooled (BYOD/Financing)',
  'BWPv2 Metered Pooled (BYOD)',
  'BWPv2 Metered Pooled (Subsidy)'
].map(value => ({ value }));

interface PlanSectionProps {
  title: string;
  plans: Plan[];
  onAddPlan: () => void;
  onRemovePlan: (index: number) => void;
  onChange: (index: number, field: keyof Plan, value: any) => void;
  isCurrentPlan?: boolean;
}

const PlanSection: React.FC<PlanSectionProps> = ({ 
  plans, 
  onAddPlan, 
  onRemovePlan, 
  onChange,
  isCurrentPlan = false
}) => (
  <div style={{ marginTop: '24px' }}>
    {plans.map((plan, index) => (
      <Card 
        key={index} 
        size="small" 
        style={{ marginBottom: '16px' }}
        title={<Text strong>Plan {index + 1}</Text>}
        bodyStyle={{ 
          display: 'flex', 
          gap: '12px', 
          alignItems: 'flex-end',
          width: '100%' 
        }}
        extra={
          <Button 
            danger 
            icon={<DeleteOutlined />} 
            onClick={() => onRemovePlan(index)}
          />
        }
      >
        <div style={{ flex: '0 0 60px' }}>
          <Text strong style={{ display: 'block', marginBottom: '8px' }}>Qty</Text>
          <InputNumber
            value={plan.quantity}
            onChange={(value) => onChange(index, 'quantity', value)}
            style={{ width: '100%' }}
            precision={0}
            min={0}
          />
        </div>

        <div style={{ flex: '0 0 80px' }}>
          <Text strong style={{ display: 'block', marginBottom: '8px' }}>Data (GB)</Text>
          <InputNumber
            value={plan.data}
            onChange={(value) => onChange(index, 'data', value)}
            style={{ width: '100%' }}
            precision={0}
            min={0}
          />
        </div>

        <div style={{ flex: '1' }}>
          <Text strong style={{ display: 'block', marginBottom: '8px' }}>Plan Name</Text>
          <AutoComplete
            value={plan.name}
            options={isCurrentPlan ? CURRENT_PLAN_OPTIONS : NEW_PLAN_OPTIONS}
            onChange={(value) => onChange(index, 'name', value)}
            style={{ width: '100%' }}
            placeholder="Select or type plan name"
            filterOption={(inputValue, option) =>
              option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
          />
        </div>

        <div style={{ flex: '0 0 100px' }}>
          <Text strong style={{ display: 'block', marginBottom: '8px' }}>MSF (Each)</Text>
          <InputNumber
            value={plan.price}
            onChange={(value) => onChange(index, 'price', value)}
            style={{ width: '100%' }}
            precision={2}
            min={0}
            prefix="$"
          />
        </div>
      </Card>
    ))}
    <Button 
      type="dashed" 
      onClick={onAddPlan} 
      icon={<PlusOutlined />}
      block
    >
      Add Plan
    </Button>
  </div>
);

export default React.memo(PlanSection);
