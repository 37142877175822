import React, { useMemo, useEffect, useState } from 'react';
import { Table, Form, message, Button, Tooltip, Space, Select, InputNumber } from 'antd';
import type { AlignType } from 'rc-table/lib/interface';
import { EditOutlined, DeleteOutlined, FileTextOutlined, ToolOutlined, SaveOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { QuoteLineItem, Product } from '../../../../types';
import { DeleteConfirmationModal, ConfigurationModal, CommentModal, RevenueTypeModal } from '../modals';
import { formatCurrency } from '../../../../utils/formatters';
import { fetchProducts } from '../../../../utils/api';
import { useQuoteLineItems } from '../../hooks';
import { revenueTypeMap } from '../../../../utils/categoryMapper';
import { SorterResult } from 'antd/es/table/interface';
import { TablePaginationConfig } from 'antd/es/table';
import { FilterValue } from 'antd/es/table/interface';
import { UserAccessLevel } from '../../../../auth/authService';

interface QuoteLineItemsTableProps {
  initialLineItems: QuoteLineItem[];
  onUpdateLineItem: (updatedItem: QuoteLineItem) => void;
  onDeleteLineItem: (itemId: string) => void;
  triggerNewLine?: boolean;
  onNewLineComplete?: () => void;
  locationId?: string;
  quoteStage?: number;
  userAccessLevel: UserAccessLevel;
}

const QuoteLineItemsTable: React.FC<QuoteLineItemsTableProps> = ({
  initialLineItems,
  onUpdateLineItem,
  onDeleteLineItem,
  triggerNewLine,
  onNewLineComplete,
  locationId,
  quoteStage,
  userAccessLevel
}) => {
  const [currentRecord, setCurrentRecord] = useState<QuoteLineItem | undefined>();
  const [commentModalVisible, setCommentModalVisible] = useState(false);
  const [currentComment, setCurrentComment] = useState('');
  const [currentLineItemId, setCurrentLineItemId] = useState('');
  const [sortedInfo, setSortedInfo] = useState<SorterResult<QuoteLineItem>>({
    columnKey: 'createdon',
    order: 'ascend'
  });

  // Check if actions are allowed based on user role and quote stage
  const isActionAllowed = userAccessLevel === 'admin' || userAccessLevel === 'quotedesk' || 
                         (userAccessLevel === 'user' && (quoteStage === 612100000 || quoteStage === 612100002));

  // Check if margin editing is allowed based on user access level and quote stage
  const canEditMargin = (userAccessLevel === 'admin' || userAccessLevel === 'quotedesk') && quoteStage === 612100009;

  const {
    lineItems,
    setLineItems,
    editingKey,
    deleteModalVisible,
    configModalVisible,
    revenueTypeModalVisible,
    products,
    loading,
    form,
    isEditing,
    isSaving,
    isDeleting,
    edit,
    cancel,
    save,
    handleDelete,
    confirmDelete,
    setConfigModalVisible,
    setRevenueTypeModalVisible,
    setDeleteModalVisible,
    setProducts,
    setLoading,
    addNewLine,
    deletingId
  } = useQuoteLineItems(initialLineItems, onUpdateLineItem, onDeleteLineItem, locationId);

  const fetchProductsData = async () => {
    setLoading(true);
    try {
      const fetchedProducts = await fetchProducts();
      setProducts(fetchedProducts);
      return fetchedProducts;
    } catch (error) {
      console.error('Error fetching products:', error);
      message.error('Failed to load products');
      return [];
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<QuoteLineItem> | SorterResult<QuoteLineItem>[],
    _: any
  ) => {
    const currentSorter = Array.isArray(sorter) ? sorter[0] : sorter;
    setSortedInfo(currentSorter);
  };

  const handleDoubleClick = (record: QuoteLineItem) => {
    if (isActionAllowed && editingKey === '' && !record.foxy_foxyquoterequestlineitemid.startsWith('temp-')) {
      form.setFieldsValue({
        ...record,
        foxy_term: record.foxy_term || 36,
        foxy_quantity: record.foxy_quantity || 1
      });
      edit(record);
    }
  };

  const productNameColumns = [
    {
      title: 'Product Name*',
      dataIndex: ['foxy_Product', 'name'],
      key: 'productName',
      sorter: (a: QuoteLineItem, b: QuoteLineItem) => {
        const nameA = a.foxy_Product?.name || '';
        const nameB = b.foxy_Product?.name || '';
        return nameA.localeCompare(nameB);
      },
      sortOrder: sortedInfo.columnKey === 'productName' ? sortedInfo.order : null,
      render: (text: string, record: QuoteLineItem) => {
        const editable = isEditing(record);
        return (
          <Space>
            {editable ? (
              <Form.Item
                name={['foxy_Product', 'name']}
                style={{ margin: 0 }}
                rules={[{ required: true, message: 'Product Name is required' }]}
              >
                <Select
                  showSearch
                  placeholder="Select a product"
                  optionFilterProp="label"
                  filterOption={(input, option) => {
                    if (!input || !option?.label || typeof option.label !== 'string') {
                      return false;
                    }
                    const productName = option.label.toLowerCase();
                    const searchTerm = input.toLowerCase().trim();
                    
                    const searchWords = searchTerm.split(/\s+/);
                    return searchWords.every(word => productName.includes(word));
                  }}
                  style={{ width: '300px' }}
                  onFocus={() => {
                    if (products.length === 0) {
                      fetchProductsData().then((fetchedProducts) => {
                        const sortedProducts = fetchedProducts.sort((a: Product, b: Product) => {
                          const nameA = a.name || '';
                          const nameB = b.name || '';
                          return nameA.localeCompare(nameB);
                        });
                        setProducts(sortedProducts);
                      });
                    }
                  }}
                  onClear={() => {
                    form.setFieldsValue({ foxy_Product: { name: '' } });
                  }}
                  allowClear
                  defaultActiveFirstOption={false}
                  notFoundContent={loading ? 'Loading...' : 'No products found'}
                  listHeight={400}
                  virtual={false}
                >
                  {products.map((product: Product) => (
                    <Select.Option 
                      key={product.name} 
                      value={product.name}
                      label={product.name}
                    >
                      {product.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              <div 
                onDoubleClick={() => handleDoubleClick(record)} 
                style={{ cursor: isActionAllowed ? 'pointer' : 'not-allowed' }}
                title={!isActionAllowed ? "Editing not allowed in current stage" : undefined}
              >
                {text}
                {record.foxy_Product?.crc9f_requiresconfiguration && (
                  <Tooltip title="Configuration Required">
                    <Button
                      icon={<ToolOutlined />}
                      onClick={() => setConfigModalVisible(true)}
                      type="text"
                      style={{ color: '#52c41a' }}
                    />
                  </Tooltip>
                )}
              </div>
            )}
          </Space>
        );
      }
    },
    {
      title: 'Revenue Type*',
      dataIndex: 'foxy_revenuetype',
      key: 'revenueType',
      sorter: (a: QuoteLineItem, b: QuoteLineItem) => {
        const typeA = revenueTypeMap[a.foxy_revenuetype] || '';
        const typeB = revenueTypeMap[b.foxy_revenuetype] || '';
        return typeA.localeCompare(typeB);
      },
      sortOrder: sortedInfo.columnKey === 'revenueType' ? sortedInfo.order : null,
      render: (type: number, record: QuoteLineItem) => {
        const editable = isEditing(record);
        const revenueType = type ? revenueTypeMap[type] : '';
        const showIcon = revenueType === 'Renewal' || revenueType === 'Upsell';
        
        const isDataComplete = record.foxy_renewaldate && 
                             record.foxy_renewaltype && 
                             record.foxy_existingqty && 
                             record.foxy_existingqty > 0 && 
                             record.foxy_existingmrr !== undefined;
        
        const iconColor = isDataComplete ? '#52c41a' : '#ff4d4f';
        
        return (
          <Space>
            {editable ? (
              <Form.Item
                name="foxy_revenuetype"
                style={{ margin: 0, minWidth: '150px' }}
                rules={[{ required: true, message: 'Revenue Type is required' }]}
              >
                <Select style={{ width: '100%' }}>
                  {Object.entries(revenueTypeMap)
                    .filter(([value, _]) => value !== '0')
                    .map(([value, label]) => (
                      <Select.Option key={value} value={parseInt(value)}>
                        {label}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            ) : (
              <div 
                onDoubleClick={() => handleDoubleClick(record)} 
                style={{ cursor: isActionAllowed ? 'pointer' : 'not-allowed' }}
                title={!isActionAllowed ? "Editing not allowed in current stage" : undefined}
              >
                <span style={{ minWidth: '80px', display: 'inline-block' }}>{revenueType}</span>
                {showIcon && (
                  <Tooltip title={isDataComplete ? "Configuration Complete" : "Configuration Required"}>
                    <Button
                      icon={<ToolOutlined />}
                      onClick={() => {
                        setCurrentRecord(record);
                        setRevenueTypeModalVisible(true);
                      }}
                      type="text"
                      style={{ color: iconColor }}
                    />
                  </Tooltip>
                )}
              </div>
            )}
          </Space>
        );
      }
    },
    {
      title: 'Term',
      dataIndex: 'foxy_term',
      key: 'term',
      sorter: (a: QuoteLineItem, b: QuoteLineItem) => (a.foxy_term || 0) - (b.foxy_term || 0),
      sortOrder: sortedInfo.columnKey === 'term' ? sortedInfo.order : null,
      render: (value: number, record: QuoteLineItem) => {
        const editable = isEditing(record);
        return editable ? (
          <Form.Item
            name="foxy_term"
            style={{ margin: 0 }}
            initialValue={36}
            rules={[{ required: true, message: 'Term is required' }]}
          >
            <InputNumber 
              min={0} 
              style={{ width: '100%' }} 
              onPressEnter={() => save(record.foxy_foxyquoterequestlineitemid)}
            />
          </Form.Item>
        ) : (
          <div 
            onDoubleClick={() => handleDoubleClick(record)} 
            style={{ cursor: isActionAllowed ? 'pointer' : 'not-allowed' }}
            title={!isActionAllowed ? "Editing not allowed in current stage" : undefined}
          >
            {record.foxy_term || 36}
          </div>
        );
      }
    },
    {
      title: 'Quantity',
      dataIndex: 'foxy_quantity',
      key: 'quantity',
      sorter: (a: QuoteLineItem, b: QuoteLineItem) => (a.foxy_quantity || 0) - (b.foxy_quantity || 0),
      sortOrder: sortedInfo.columnKey === 'quantity' ? sortedInfo.order : null,
      render: (value: number, record: QuoteLineItem) => {
        const editable = isEditing(record);
        return editable ? (
          <Form.Item
            name="foxy_quantity"
            style={{ margin: 0 }}
            initialValue={1}
            rules={[{ required: true, message: 'Quantity is required' }]}
          >
            <InputNumber 
              min={0} 
              style={{ width: '100%' }} 
              onPressEnter={() => save(record.foxy_foxyquoterequestlineitemid)}
            />
          </Form.Item>
        ) : (
          <div 
            onDoubleClick={() => handleDoubleClick(record)} 
            style={{ cursor: isActionAllowed ? 'pointer' : 'not-allowed' }}
            title={!isActionAllowed ? "Editing not allowed in current stage" : undefined}
          >
            {record.foxy_quantity || 1}
          </div>
        );
      }
    },
    {
      title: 'Each',
      dataIndex: 'foxy_each',
      key: 'each',
      sorter: (a: QuoteLineItem, b: QuoteLineItem) => (a.foxy_each || 0) - (b.foxy_each || 0),
      sortOrder: sortedInfo.columnKey === 'each' ? sortedInfo.order : null,
      render: (value: number, record: QuoteLineItem) => {
        const editable = isEditing(record);
        return editable ? (
          <Form.Item
            name="foxy_each"
            style={{ margin: 0 }}
            rules={[{ required: true, message: 'Each is required' }]}
          >
            <InputNumber
              min={0}
              step={0.01}
              precision={2}
              style={{ width: '100%' }}
              onPressEnter={() => save(record.foxy_foxyquoterequestlineitemid)}
              onChange={(value) => {
                const quantity = form.getFieldValue('foxy_quantity') || 1;
                const each = value || 0;
                const term = form.getFieldValue('foxy_term') || 36;
                const calculatedMRR = quantity * each;
                const calculatedTCV = calculatedMRR * term;
                
                const updatedLineItems = lineItems.map((item: QuoteLineItem) => {
                  if (item.foxy_foxyquoterequestlineitemid === editingKey) {
                    return {
                      ...item,
                      foxy_mrr: calculatedMRR,
                      foxy_linetcv: calculatedTCV,
                      foxy_quantity: quantity,
                      foxy_term: term,
                      foxy_each: each
                    };
                  }
                  return item;
                });
                setLineItems(updatedLineItems);
              }}
            />
          </Form.Item>
        ) : (
          <div 
            onDoubleClick={() => handleDoubleClick(record)} 
            style={{ cursor: isActionAllowed ? 'pointer' : 'not-allowed' }}
            title={!isActionAllowed ? "Editing not allowed in current stage" : undefined}
          >
            {formatCurrency(record.foxy_each || 0)}
          </div>
        );
      }
    },
    {
      title: 'MRR',
      dataIndex: 'foxy_mrr',
      key: 'mrr',
      sorter: (a: QuoteLineItem, b: QuoteLineItem) => (a.foxy_mrr || 0) - (b.foxy_mrr || 0),
      sortOrder: sortedInfo.columnKey === 'mrr' ? sortedInfo.order : null,
      render: (mrr: number, record: QuoteLineItem) => {
        if (isEditing(record)) {
          const quantity = form.getFieldValue('foxy_quantity') || 1;
          const each = form.getFieldValue('foxy_each') || 0;
          const calculatedMRR = quantity * each;
          return formatCurrency(calculatedMRR);
        }
        return formatCurrency(mrr);
      }
    },
    {
      title: 'TCV',
      dataIndex: 'foxy_linetcv',
      key: 'tcv',
      sorter: (a: QuoteLineItem, b: QuoteLineItem) => (a.foxy_linetcv || 0) - (b.foxy_linetcv || 0),
      sortOrder: sortedInfo.columnKey === 'tcv' ? sortedInfo.order : null,
      render: (tcv: number, record: QuoteLineItem) => {
        if (isEditing(record)) {
          const quantity = form.getFieldValue('foxy_quantity') || 1;
          const each = form.getFieldValue('foxy_each') || 0;
          const term = form.getFieldValue('foxy_term') || 36;
          const calculatedMRR = quantity * each;
          const calculatedTCV = calculatedMRR * term;
          return formatCurrency(calculatedTCV);
        }
        return formatCurrency(tcv);
      }
    },
    {
      title: 'Margin',
      dataIndex: 'foxy_margin',
      key: 'margin',
      hidden: quoteStage !== 612100009,
      sorter: (a: QuoteLineItem, b: QuoteLineItem) => (a.foxy_margin || 0) - (b.foxy_margin || 0),
      sortOrder: sortedInfo.columnKey === 'margin' ? sortedInfo.order : null,
      render: (value: number, record: QuoteLineItem) => {
        const editable = isEditing(record) && canEditMargin;
        return editable ? (
          <Form.Item
            name="foxy_margin"
            style={{ margin: 0 }}
            rules={[{ required: true, message: 'Margin is required' }]}
          >
            <InputNumber
              min={0}
              max={100}
              formatter={value => `${value}%`}
              parser={(value: string | undefined): number => {
                const parsed = value ? parseFloat(value.replace('%', '')) : 0;
                return isNaN(parsed) ? 0 : parsed;
              }}
              style={{ width: '100%' }}
              onPressEnter={() => save(record.foxy_foxyquoterequestlineitemid)}
            />
          </Form.Item>
        ) : (
          <div 
            onDoubleClick={() => canEditMargin && handleDoubleClick(record)} 
            style={{ cursor: canEditMargin ? 'pointer' : 'not-allowed' }}
            title={!canEditMargin ? "You don't have permission to edit margin" : undefined}
          >
            {quoteStage === 612100009 ? `${record.foxy_margin || 0}%` : null}
          </div>
        );
      }
    },
    {
      title: '',
      key: 'actions',
      align: 'center' as AlignType,
      render: (_: unknown, record: QuoteLineItem) => {
        const editable = isEditing(record);
        const saving = isSaving(record);
        const deleting = isDeleting(record);
        const iconColor = record.foxy_comment ? '#1890ff' : '#d9d9d9';
        return (
          <Space>
            {editable ? (
              <>
                <Button
                  icon={saving ? <LoadingOutlined /> : <SaveOutlined />}
                  onClick={() => save(record.foxy_foxyquoterequestlineitemid)}
                  style={{ marginRight: 8 }}
                  type="link"
                  loading={saving}
                  disabled={saving}
                >
                  {saving ? 'Saving...' : 'Save'}
                </Button>
                <Button
                  icon={<CloseOutlined />}
                  onClick={cancel}
                  type="link"
                  disabled={saving}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Tooltip title={!isActionAllowed ? "Actions not allowed in current stage" : "Edit"}>
                  <Button
                    disabled={!isActionAllowed || editingKey !== ''}
                    onClick={() => {
                      form.setFieldsValue({
                        ...record,
                        foxy_term: record.foxy_term || 36,
                        foxy_quantity: record.foxy_quantity || 1
                      });
                      edit(record);
                    }}
                    icon={<EditOutlined />}
                    type="link"
                    style={{ marginRight: 8 }}
                  />
                </Tooltip>
                <Tooltip title={!isActionAllowed ? "Actions not allowed in current stage" : "Delete"}>
                  <Button
                    onClick={() => handleDelete(record.foxy_foxyquoterequestlineitemid)}
                    icon={deleting ? <LoadingOutlined /> : <DeleteOutlined />}
                    type="link"
                    style={{ color: '#ff4d4f', marginRight: 8 }}
                    loading={deleting}
                    disabled={!isActionAllowed || deleting}
                  />
                </Tooltip>
                <Tooltip title="Notes">
                  <Button
                    icon={<FileTextOutlined style={{ color: iconColor }} />}
                    type="text"
                    onClick={() => handleCommentClick(record)}
                  />
                </Tooltip>
              </>
            )}
          </Space>
        );
      }
    }
  ];

  const totalMRR = useMemo(() => lineItems.reduce((sum: number, item: QuoteLineItem) => sum + item.foxy_mrr, 0), [lineItems]);
  const totalTCV = useMemo(() => lineItems.reduce((sum: number, item: QuoteLineItem) => sum + item.foxy_linetcv, 0), [lineItems]);

  useEffect(() => {
    if (triggerNewLine && isActionAllowed) {
      addNewLine();
      if (onNewLineComplete) {
        onNewLineComplete();
      }
    }
  }, [triggerNewLine, addNewLine, onNewLineComplete, isActionAllowed]);

  const handleCommentConfirm = (updatedComment: string) => {
    if (currentRecord && currentRecord.foxy_foxyquoterequestlineitemid) {
      const updatedItem: QuoteLineItem = { 
        ...currentRecord, 
        foxy_comment: updatedComment 
      };
      setLineItems((prevItems: QuoteLineItem[]) => 
        prevItems.map((item: QuoteLineItem) => 
          item.foxy_foxyquoterequestlineitemid === updatedItem.foxy_foxyquoterequestlineitemid 
            ? updatedItem 
            : item
        )
      );
      onUpdateLineItem(updatedItem);
    }
    setCommentModalVisible(false);
  };

  const handleCommentClick = (record: QuoteLineItem) => {
    setCurrentRecord(record);
    setCurrentComment(record.foxy_comment || '');
    setCurrentLineItemId(record.foxy_foxyquoterequestlineitemid);
    setCommentModalVisible(true);
  };

  return (
    <>
      <div style={{ marginTop: '20px' }}>
        <Form 
          form={form} 
          component={false}
          onValuesChange={(_, allValues) => {
            const quantity = allValues.foxy_quantity || 1;
            const each = allValues.foxy_each || 0;
            const term = allValues.foxy_term || 36;
            const calculatedMRR = quantity * each;
            const calculatedTCV = calculatedMRR * term;

            const updatedLineItems = lineItems.map((item: QuoteLineItem) => {
              if (item.foxy_foxyquoterequestlineitemid === editingKey) {
                return {
                  ...item,
                  ...allValues,
                  foxy_mrr: calculatedMRR,
                  foxy_linetcv: calculatedTCV
                };
              }
              return item;
            });
            setLineItems(updatedLineItems);
          }}
        >
          <Table
            columns={productNameColumns.filter(col => !col.hidden)}
            dataSource={lineItems}
            rowKey="foxy_foxyquoterequestlineitemid"
            pagination={false}
            scroll={{ x: 'max-content' }}
            className="rounded-table"
            onChange={handleTableChange}
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={5}>
                    <strong>Total</strong>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5}>
                    <strong>{formatCurrency(totalMRR)}</strong>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6}>
                    <strong>{formatCurrency(totalTCV)}</strong>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7} />
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </Form>
      </div>

      <DeleteConfirmationModal
        open={deleteModalVisible}
        onConfirm={confirmDelete}
        onCancel={() => setDeleteModalVisible(false)}
        loading={!!deletingId}
        message="Are you sure you want to delete this line item? This action cannot be undone."
      />
      <ConfigurationModal
        open={configModalVisible}
        onOk={() => setConfigModalVisible(false)}
        onCancel={() => setConfigModalVisible(false)}
      />
      <RevenueTypeModal
        open={revenueTypeModalVisible}
        onCancel={() => setRevenueTypeModalVisible(false)}
        initialValues={currentRecord}
        onOk={(updatedItem: QuoteLineItem) => {
          // Update local state
          const newData = lineItems.map((item: QuoteLineItem) =>
            item.foxy_foxyquoterequestlineitemid === updatedItem.foxy_foxyquoterequestlineitemid
              ? updatedItem
              : item
          );
          setLineItems(newData);
          
          // Notify parent
          onUpdateLineItem(updatedItem);
          
          // Close modal
          setRevenueTypeModalVisible(false);
        }}
      />
      <CommentModal
        open={commentModalVisible}
        comment={currentComment}
        onCancel={() => setCommentModalVisible(false)}
        onConfirm={handleCommentConfirm}
        lineItemId={currentLineItemId}
      />
    </>
  );
};

export default QuoteLineItemsTable;
