import React, { useRef, useImperativeHandle, forwardRef } from 'react';
import { message } from 'antd';
import { QuoteLocation, QuoteLineItem } from '../../../types';

interface QuoteEmailTableProps {
  locations: QuoteLocation[];
  lineItems: { [key: string]: QuoteLineItem[] };
}

export interface QuoteEmailTableRef {
  copyToClipboard: () => void;
}

const QuoteEmailTable = forwardRef<QuoteEmailTableRef, QuoteEmailTableProps>(({
  locations,
  lineItems,
}, ref) => {
  const tableRef = useRef<HTMLDivElement>(null);

  const formatNumber = (value: number) => {
    return value.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };

  const calculateLocationTotal = (locationId: string) => {
    const locationLineItems = lineItems[locationId] || [];
    return locationLineItems.reduce((total, item) => total + (item.foxy_mrr || 0), 0);
  };

  const calculateGrandTotal = () => {
    return locations.reduce((total, location) => {
      return total + calculateLocationTotal(location.foxy_foxyquoterequestlocationid);
    }, 0);
  };

  const truncateProductName = (name: string) => {
    if (!name) return '';
    if (name.length > 40) {
      return `${name.substring(0, 37)}...`;
    }
    return name;
  };

  useImperativeHandle(ref, () => ({
    copyToClipboard: () => {
      if (tableRef.current) {
        const range = document.createRange();
        range.selectNode(tableRef.current);
        const selection = window.getSelection();
        if (selection) {
          selection.removeAllRanges();
          selection.addRange(range);
          
          try {
            const success = document.execCommand('copy');
            selection.removeAllRanges();
            if (success) {
              message.success('Quote copied to clipboard');
            } else {
              message.error('Failed to copy quote');
            }
          } catch (err) {
            console.error('Copy failed:', err);
            message.error('Failed to copy quote');
          }
        }
      }
    }
  }));

  const showLocationSubtotal = locations.length > 1;

  return (
    <div ref={tableRef} style={{ position: 'absolute', left: '-9999px' }}>
      {locations.map((location, index) => {
        const locationLineItems = lineItems[location.foxy_foxyquoterequestlocationid] || [];
        const locationTotal = calculateLocationTotal(location.foxy_foxyquoterequestlocationid);

        return (
          <div key={location.foxy_foxyquoterequestlocationid} style={{ marginBottom: '40px' }}>
            {/* Location Header */}
            <div style={{ 
              padding: '8px', 
              fontWeight: 'bold',
              marginBottom: '10px',
              textDecoration: 'underline'
            }}>
              {location.foxy_Building?.foxy_fulladdress || 'Unnamed Location'}
            </div>

            <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '10px', maxWidth: '800px' }}>
              {/* Table Headers */}
              <thead>
                <tr>
                  <th style={{ 
                    padding: '8px', 
                    textAlign: 'left', 
                    width: '340px', 
                    minWidth: '340px',
                    borderBottom: '1px solid #666' 
                  }}>Product</th>
                  <th style={{ 
                    padding: '8px', 
                    textAlign: 'right', 
                    width: '100px',
                    minWidth: '100px', 
                    borderBottom: '1px solid #666' 
                  }}>Term</th>
                  <th style={{ 
                    padding: '8px', 
                    textAlign: 'right', 
                    width: '100px',
                    minWidth: '100px', 
                    borderBottom: '1px solid #666' 
                  }}>Quantity</th>
                  <th style={{ 
                    padding: '8px', 
                    textAlign: 'right', 
                    width: '100px',
                    minWidth: '100px', 
                    borderBottom: '1px solid #666' 
                  }}>Each</th>
                  <th style={{ 
                    padding: '8px', 
                    textAlign: 'right', 
                    width: '100px',
                    minWidth: '100px', 
                    borderBottom: '1px solid #666' 
                  }}>Total</th>
                </tr>
              </thead>
              <tbody>
                {/* Location Line Items */}
                {locationLineItems.map((item) => (
                  <tr key={item.foxy_foxyquoterequestlineitemid}>
                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd', whiteSpace: 'nowrap', wordBreak: 'keep-all', width: '340px', minWidth: '340px' }}>
                      {truncateProductName(item.foxy_Product?.name || '')}
                    </td>
                    <td style={{ padding: '8px', textAlign: 'right', borderBottom: '1px solid #ddd', width: '100px', minWidth: '100px' }}>
                      {item.foxy_term}
                    </td>
                    <td style={{ padding: '8px', textAlign: 'right', borderBottom: '1px solid #ddd', width: '100px', minWidth: '100px' }}>
                      {item.foxy_quantity}
                    </td>
                    <td style={{ padding: '8px', textAlign: 'right', borderBottom: '1px solid #ddd', width: '100px', minWidth: '100px' }}>
                      ${formatNumber(item.foxy_each)}
                    </td>
                    <td style={{ padding: '8px', textAlign: 'right', borderBottom: '1px solid #ddd', width: '100px', minWidth: '100px' }}>
                      ${formatNumber(item.foxy_mrr)}
                    </td>
                  </tr>
                ))}
                
                {/* Location Subtotal */}
                {showLocationSubtotal && (
                  <>
                    <tr>
                      <td colSpan={5} style={{ height: '10px' }}></td>
                    </tr>
                    <tr style={{ borderTop: '1px solid #666' }}>
                      <td style={{ 
                        padding: '8px', 
                        fontWeight: 'bold'
                      }}>Location Subtotal</td>
                      <td colSpan={3}></td>
                      <td style={{ 
                        padding: '8px', 
                        textAlign: 'right', 
                        fontWeight: 'bold'
                      }}>${formatNumber(locationTotal)}</td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>

            {/* Add extra spacing between locations that will copy to Outlook */}
            {index < locations.length - 1 && (
              <div style={{ height: '20px', borderBottom: '1px solid transparent' }}>&nbsp;</div>
            )}
          </div>
        );
      })}
      
      {/* Grand Total */}
      <table style={{ width: '100%', maxWidth: '800px', marginTop: '20px' }}>
        <tbody>
          <tr>
            <td colSpan={4} style={{ 
              padding: '8px', 
              fontWeight: 'bold',
              borderTop: '1px solid #666'
            }}>Grand Total</td>
            <td style={{ 
              padding: '8px', 
              textAlign: 'right', 
              fontWeight: 'bold',
              width: '15%',
              borderTop: '1px solid #666'
            }}>${formatNumber(calculateGrandTotal())}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
});

QuoteEmailTable.displayName = 'QuoteEmailTable';

export { QuoteEmailTable };
export type { QuoteEmailTableProps };
