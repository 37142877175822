import { message } from 'antd';
import { PlanalyzerState } from '../types';
import { generateHtmlTable, generateNewOnlyHtmlTable } from '../utils/calculations';

export const useClipboard = (state: PlanalyzerState) => {
  const copyTableToClipboard = async () => {
    const htmlTable = generateHtmlTable(
      state.currentPlans,
      state.newPlans,
      state.currentFixedCosts,
      state.newFixedCosts
    );
    
    try {
      await navigator.clipboard.write([
        new ClipboardItem({
          'text/html': new Blob([htmlTable], { type: 'text/html' }),
          'text/plain': new Blob([htmlTable], { type: 'text/plain' })
        })
      ]);
      message.success('Table copied to clipboard');
    } catch (err) {
      // Fallback for browsers that don't support clipboard.write
      const tempTextArea = document.createElement('textarea');
      tempTextArea.value = htmlTable;
      document.body.appendChild(tempTextArea);
      tempTextArea.select();
      try {
        document.execCommand('copy');
        message.success('Table copied to clipboard');
      } catch (err) {
        console.error('Copy failed:', err);
        message.error('Failed to copy table');
      }
      document.body.removeChild(tempTextArea);
    }
  };

  const copyNewTableToClipboard = async () => {
    const htmlTable = generateNewOnlyHtmlTable(
      state.newPlans,
      state.newFixedCosts
    );
    
    try {
      await navigator.clipboard.write([
        new ClipboardItem({
          'text/html': new Blob([htmlTable], { type: 'text/html' }),
          'text/plain': new Blob([htmlTable], { type: 'text/plain' })
        })
      ]);
      message.success('New table copied to clipboard');
    } catch (err) {
      // Fallback for browsers that don't support clipboard.write
      const tempTextArea = document.createElement('textarea');
      tempTextArea.value = htmlTable;
      document.body.appendChild(tempTextArea);
      tempTextArea.select();
      try {
        document.execCommand('copy');
        message.success('New table copied to clipboard');
      } catch (err) {
        console.error('Copy failed:', err);
        message.error('Failed to copy table');
      }
      document.body.removeChild(tempTextArea);
    }
  };

  const copyStateToClipboard = async () => {
    const jsonString = JSON.stringify(state, null, 2);
    
    try {
      await navigator.clipboard.writeText(jsonString);
      message.success('State copied to clipboard');
    } catch (err) {
      // Fallback for browsers that don't support clipboard.writeText
      const tempTextArea = document.createElement('textarea');
      tempTextArea.value = jsonString;
      document.body.appendChild(tempTextArea);
      tempTextArea.select();
      try {
        document.execCommand('copy');
        message.success('State copied to clipboard');
      } catch (err) {
        console.error('Copy failed:', err);
        message.error('Failed to copy state');
      }
      document.body.removeChild(tempTextArea);
    }
  };

  const loadStateFromClipboard = async (
    onLoadState: (state: PlanalyzerState) => void
  ) => {
    try {
      const clipText = await navigator.clipboard.readText();
      const cleanText = clipText.trim();
      
      try {
        const state = JSON.parse(cleanText) as PlanalyzerState;
        
        if (
          state.currentFixedCosts &&
          state.newFixedCosts &&
          Array.isArray(state.currentPlans) &&
          Array.isArray(state.newPlans)
        ) {
          onLoadState(state);
          message.success('State loaded from clipboard');
        } else {
          message.error('Invalid state structure in clipboard');
        }
      } catch (parseError) {
        console.error('JSON Parse Error:', parseError);
        message.error('Failed to parse clipboard content');
      }
    } catch (err) {
      console.error('Clipboard Error:', err);
      message.error('Failed to read from clipboard');
    }
  };

  return {
    copyTableToClipboard,
    copyNewTableToClipboard,
    copyStateToClipboard,
    loadStateFromClipboard
  };
};
