import React from 'react';
import { InputNumber, Input, Button, Space, Typography } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { InputFieldProps } from '../types';

const { Text } = Typography;

const InputField: React.FC<InputFieldProps> = ({ 
  label, 
  value, 
  onChange,
  type = 'number',
  step,
  onCopyToNew,
  showCopyButton = false,
  prefix = '',
  isQuantity = false,
  style = {}
}) => {
  const handleChange = (newValue: number | string | null) => {
    if (type === 'number') {
      onChange({ target: { value: newValue?.toString() || '0' } } as React.ChangeEvent<HTMLInputElement>);
    } else {
      onChange({ target: { value: newValue?.toString() || '' } } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <div style={{ marginBottom: '16px' }}>
      <Text strong style={{ display: 'block', marginBottom: '8px' }}>{label}</Text>
      <Space>
        {type === 'number' ? (
          <InputNumber
            value={value === '' ? null : Number(value)}
            onChange={handleChange}
            style={{ width: '100%', ...style }}
            step={step}
            precision={isQuantity ? 0 : 2}
            prefix={prefix}
            min={0}
          />
        ) : (
          <Input
            value={value}
            onChange={(e) => handleChange(e.target.value)}
            style={{ width: '300px', ...style }}
          />
        )}
        {showCopyButton && onCopyToNew && (
          <Button 
            icon={<ArrowRightOutlined />}
            onClick={onCopyToNew}
            type="primary"
            ghost
          />
        )}
      </Space>
    </div>
  );
};

export default React.memo(InputField);
