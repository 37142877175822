import * as XLSX from 'xlsx';
import { QuoteLineItem, QuoteLocation } from '../types';
import { revenueTypeMap } from './categoryMapper';

interface ExcelRow {
  Location: string;
  Product: string;
  'Revenue Type': string;
  Quantity: number;
  Each: number;
  MRR: number;
  Term: number;
  TCV: number;
  Comment?: string;
}

export const exportToExcel = (
  locations: QuoteLocation[],
  lineItems: { [key: string]: QuoteLineItem[] },
  quoteId: string,
  accountName: string
) => {
  const rows: ExcelRow[] = [];

  // Flatten the data structure into rows
  locations.forEach((location) => {
    const locationAddress = location.foxy_Building?.foxy_fulladdress || 'Unnamed Location';
    const locationLineItems = lineItems[location.foxy_foxyquoterequestlocationid] || [];

    locationLineItems.forEach((item) => {
      rows.push({
        Location: locationAddress,
        Product: item.foxy_Product?.name || '',
        'Revenue Type': revenueTypeMap[item.foxy_revenuetype] || 'Unknown',
        Quantity: item.foxy_quantity,
        Each: item.foxy_each,
        MRR: item.foxy_mrr,
        Term: item.foxy_term,
        TCV: item.foxy_linetcv,
        Comment: item.foxy_comment
      });
    });
  });

  // Create workbook and worksheet
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(rows);

  // Set column widths
  const colWidths = [
    { wch: 40 }, // Location
    { wch: 40 }, // Product
    { wch: 15 }, // Revenue Type
    { wch: 10 }, // Quantity
    { wch: 12 }, // Each
    { wch: 12 }, // MRR
    { wch: 10 }, // Term
    { wch: 12 }, // TCV
    { wch: 40 }  // Comment
  ];
  ws['!cols'] = colWidths;

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(wb, ws, 'Quote Details');

  // Generate filename with quote ID and company name
  // Replace invalid characters with underscores using a simpler regex
  const safeAccountName = accountName.replace(/[^a-zA-Z0-9-_. ]/g, '_');
  const filename = `Quote_${quoteId}_${safeAccountName}.xlsx`;

  // Generate Excel file
  XLSX.writeFile(wb, filename);
};
