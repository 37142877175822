import React from 'react';
import { Layout, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import './PlanalyzerHeader.css';

const { Header } = Layout;


const PlanalyzerHeader: React.FC = () => {
  return (
    <Header className="app-header">
      {/* Logo and Title section */}
      <div className="header-left">
        <Link to="/residual-check" style={{ textDecoration: 'none' }}>
          <div className="logo-container">
            <img src="/foxylogo.png" alt="Foxy Logo" className="logo-image" />
          </div>
        </Link>
      </div>

      {/* Centered title */}
      <div className="header-center">
        <span className="planalyzer-logo-text">Planalyzer Express</span>
      </div>

      {/* Avatar section */}
      <div className="header-right">
        <div className="user-avatar">
          <Avatar 
            icon={<UserOutlined />} 
            className="avatar-icon"
          />
        </div>
      </div>
    </Header>
  );
};

export default PlanalyzerHeader;
