import { Plan, FixedCosts } from '../types';

export const calculateTotal = (plans: Plan[], fixedCosts: FixedCosts): number => {
  const planTotal = plans.reduce((sum, plan) => {
    const quantity = Number(plan.quantity || 0);
    const price = Number(plan.price || 0);
    return sum + (quantity * price);
  }, 0);

  const fixedTotal = 
    Number(fixedCosts.roaming || 0) + 
    Number(fixedCosts.addOns || 0);

  return planTotal + fixedTotal;
};

export const generateHtmlTable = (
  currentPlans: Plan[], 
  newPlans: Plan[], 
  currentFixedCosts: FixedCosts, 
  newFixedCosts: FixedCosts
): string => {
  const currentTotal = calculateTotal(currentPlans, currentFixedCosts);
  const newTotal = calculateTotal(newPlans, newFixedCosts);

  const formatCurrency = (amount: number) => `$${amount.toFixed(2)}`;
  const shouldShowFixedCost = (field: keyof FixedCosts) => 
    currentFixedCosts[field] > 0 || newFixedCosts[field] > 0;

  return `
    <table border="1" style="border-collapse: collapse; min-width: 800px; width: 100%;">
      <tr>
        <th colspan="3" style="text-align: left; padding: 8px; background-color: #f5f5f5; font-weight: bold;">Current</th>
        <th colspan="3" style="text-align: left; padding: 8px; background-color: #f5f5f5; font-weight: bold;">New</th>
      </tr>
      <tr>
        <th style="text-align: left; padding: 8px; background-color: #f5f5f5; font-weight: bold;"></th>
        <th style="text-align: left; padding: 8px; background-color: #f5f5f5; font-weight: bold;">Monthly Fee</th>
        <th style="text-align: left; padding: 8px; background-color: #f5f5f5; font-weight: bold;">Total</th>
        <th style="text-align: left; padding: 8px; background-color: #f5f5f5; font-weight: bold;"></th>
        <th style="text-align: left; padding: 8px; background-color: #f5f5f5; font-weight: bold;">Monthly Fee</th>
        <th style="text-align: left; padding: 8px; background-color: #f5f5f5; font-weight: bold;">Total</th>
      </tr>
      ${currentPlans.map((plan, i) => `
        <tr>
          <td style="padding: 8px;">${plan.quantity} x ${plan.name} (${plan.data}GB)</td>
          <td style="padding: 8px;">${formatCurrency(Number(plan.price))}</td>
          <td style="padding: 8px;">${formatCurrency(Number(plan.quantity) * Number(plan.price))}</td>
          <td style="padding: 8px;">${newPlans[i] ? `${newPlans[i].quantity} x ${newPlans[i].name} (${newPlans[i].data}GB)` : ''}</td>
          <td style="padding: 8px;">${newPlans[i] ? formatCurrency(Number(newPlans[i].price)) : ''}</td>
          <td style="padding: 8px;">${newPlans[i] ? formatCurrency(Number(newPlans[i].quantity) * Number(newPlans[i].price)) : ''}</td>
        </tr>
      `).join('')}
      ${newPlans.slice(currentPlans.length).map(plan => `
        <tr>
          <td style="padding: 8px;"></td>
          <td style="padding: 8px;"></td>
          <td style="padding: 8px;"></td>
          <td style="padding: 8px;">${plan.quantity} x ${plan.name} (${plan.data}GB)</td>
          <td style="padding: 8px;">${formatCurrency(Number(plan.price))}</td>
          <td style="padding: 8px;">${formatCurrency(Number(plan.quantity) * Number(plan.price))}</td>
        </tr>
      `).join('')}
      ${shouldShowFixedCost('roaming') ? `
        <tr>
          <td style="padding: 8px;">Roaming</td>
          <td style="padding: 8px;"></td>
          <td style="padding: 8px;">${formatCurrency(currentFixedCosts.roaming)}</td>
          <td style="padding: 8px;">Roaming</td>
          <td style="padding: 8px;"></td>
          <td style="padding: 8px;">${formatCurrency(newFixedCosts.roaming)}</td>
        </tr>
      ` : ''}
      ${shouldShowFixedCost('addOns') ? `
        <tr>
          <td style="padding: 8px;">Add-Ons</td>
          <td style="padding: 8px;"></td>
          <td style="padding: 8px;">${formatCurrency(currentFixedCosts.addOns)}</td>
          <td style="padding: 8px;">Add-Ons</td>
          <td style="padding: 8px;"></td>
          <td style="padding: 8px;">${formatCurrency(newFixedCosts.addOns)}</td>
        </tr>
      ` : ''}
      <tr>
        <td colspan="6" style="padding: 4px;"></td>
      </tr>
      <tr>
        <td style="padding: 8px; font-weight: bold;">Total</td>
        <td style="padding: 8px;"></td>
        <td style="padding: 8px; font-weight: bold;">${formatCurrency(currentTotal)}</td>
        <td style="padding: 8px; font-weight: bold;">Total</td>
        <td style="padding: 8px;"></td>
        <td style="padding: 8px; font-weight: bold;">${formatCurrency(newTotal)}</td>
      </tr>
    </table>
  `;
};

export const generateNewOnlyHtmlTable = (
  newPlans: Plan[], 
  newFixedCosts: FixedCosts
): string => {
  const newTotal = calculateTotal(newPlans, newFixedCosts);
  const formatCurrency = (amount: number) => `$${amount.toFixed(2)}`;
  const shouldShowFixedCost = (field: keyof FixedCosts) => newFixedCosts[field] > 0;

  return `
    <table border="1" style="border-collapse: collapse; min-width: 400px; width: 100%;">
      <tr>
        <th style="text-align: left; padding: 8px; background-color: #f5f5f5; font-weight: bold;"></th>
        <th style="text-align: left; padding: 8px; background-color: #f5f5f5; font-weight: bold;">Monthly Fee</th>
        <th style="text-align: left; padding: 8px; background-color: #f5f5f5; font-weight: bold;">Total</th>
      </tr>
      ${newPlans.map(plan => `
        <tr>
          <td style="padding: 8px;">${plan.quantity} x ${plan.name} (${plan.data}GB)</td>
          <td style="padding: 8px;">${formatCurrency(Number(plan.price))}</td>
          <td style="padding: 8px;">${formatCurrency(Number(plan.quantity) * Number(plan.price))}</td>
        </tr>
      `).join('')}
      ${shouldShowFixedCost('roaming') ? `
        <tr>
          <td style="padding: 8px;">Roaming</td>
          <td style="padding: 8px;"></td>
          <td style="padding: 8px;">${formatCurrency(newFixedCosts.roaming)}</td>
        </tr>
      ` : ''}
      ${shouldShowFixedCost('addOns') ? `
        <tr>
          <td style="padding: 8px;">Add-Ons</td>
          <td style="padding: 8px;"></td>
          <td style="padding: 8px;">${formatCurrency(newFixedCosts.addOns)}</td>
        </tr>
      ` : ''}
      <tr>
        <td colspan="3" style="padding: 4px;"></td>
      </tr>
      <tr>
        <td style="padding: 8px; font-weight: bold;">Total</td>
        <td style="padding: 8px;"></td>
        <td style="padding: 8px; font-weight: bold;">${formatCurrency(newTotal)}</td>
      </tr>
    </table>
  `;
};
