import React from 'react';
import { Button, Space } from 'antd';
import { CopyOutlined, SaveOutlined } from '@ant-design/icons';

interface ActionButtonsProps {
  onCopyTable: () => void;
  onCopyNewTable: () => void;
  onSave: () => void;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ 
  onCopyTable,
  onCopyNewTable,
  onSave 
}) => (
  <Space style={{ marginTop: '24px' }}>
    <Button icon={<CopyOutlined />} onClick={onCopyTable} type="primary">
      Copy Table
    </Button>
    <Button icon={<CopyOutlined />} onClick={onCopyNewTable} type="primary" ghost>
      Copy New Table
    </Button>
    <Button 
      type="primary"
      icon={<SaveOutlined />}
      onClick={onSave}
      style={{ background: '#722ed1', borderColor: '#722ed1' }}
    >
      Save
    </Button>
  </Space>
);

export default React.memo(ActionButtons);
