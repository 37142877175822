import { useState, useEffect } from 'react';
import { message } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Plan, FixedCosts, PlanalyzerState } from '../types';
import { API_BASE_URL, getAuthHeaders } from '../../../utils/api';

const emptyFixedCosts: FixedCosts = {
  roaming: 0,
  addOns: 0
};

export const usePlanalyzer = (id?: string) => {
  const navigate = useNavigate();
  const [currentFixedCosts, setCurrentFixedCosts] = useState<FixedCosts>(emptyFixedCosts);
  const [newFixedCosts, setNewFixedCosts] = useState<FixedCosts>(emptyFixedCosts);
  const [currentPlans, setCurrentPlans] = useState<Plan[]>([]);
  const [newPlans, setNewPlans] = useState<Plan[]>([]);

  useEffect(() => {
    const loadPlanalyzerData = async () => {
      if (!id) return;

      try {
        const headers = await getAuthHeaders();
        const response = await axios.get(
          `${API_BASE_URL}/getRapidPlanalyzer?id=${id}`,
          { headers }
        );
        
        if (!response.data || response.data.length === 0) {
          navigate('/404');
          return;
        }

        if (!response.data.crc9f_ledgersave) return;

        try {
          const state = JSON.parse(response.data.crc9f_ledgersave) as PlanalyzerState;
          
          const validationErrors = [];
          if (!state.currentFixedCosts) validationErrors.push('Missing currentFixedCosts');
          if (!state.newFixedCosts) validationErrors.push('Missing newFixedCosts');
          if (!Array.isArray(state.currentPlans)) validationErrors.push('currentPlans is not an array');
          if (!Array.isArray(state.newPlans)) validationErrors.push('newPlans is not an array');

          if (validationErrors.length > 0) {
            message.error('Invalid saved data format');
            return;
          }

          setCurrentFixedCosts(state.currentFixedCosts);
          setNewFixedCosts(state.newFixedCosts);
          setCurrentPlans(state.currentPlans);
          setNewPlans(state.newPlans);

          message.success('Analysis loaded successfully');
          
        } catch (parseError) {
          message.error('Failed to parse saved analysis');
        }
      } catch (error: any) {
        if (error.response?.status === 404 || error.response?.status === 400) {
          navigate('/404');
          return;
        }
        message.error('Failed to load analysis from server');
      }
    };

    loadPlanalyzerData();
  }, [id, navigate]);

  const handleFixedCostChange = (
    side: 'current' | 'new',
    field: keyof FixedCosts,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value === '' ? 0 : Number(e.target.value);
    if (side === 'current') {
      setCurrentFixedCosts(prev => ({ ...prev, [field]: value }));
    } else {
      setNewFixedCosts(prev => ({ ...prev, [field]: value }));
    }
  };

  const handlePlanChange = (
    side: 'current' | 'new',
    index: number,
    field: keyof Plan,
    value: string | number | null
  ) => {
    const plans = side === 'current' ? [...currentPlans] : [...newPlans];
    plans[index] = { ...plans[index], [field]: value };
    side === 'current' ? setCurrentPlans(plans) : setNewPlans(plans);
  };

  const addPlan = (side: 'current' | 'new') => {
    const newPlan: Plan = {
      quantity: null,
      data: null,
      name: '',
      price: null
    };
    
    if (side === 'current') {
      setCurrentPlans([...currentPlans, newPlan]);
    } else {
      setNewPlans([...newPlans, newPlan]);
    }
  };

  const removePlan = (side: 'current' | 'new', index: number) => {
    if (side === 'current') {
      setCurrentPlans(currentPlans.filter((_, i) => i !== index));
    } else {
      setNewPlans(newPlans.filter((_, i) => i !== index));
    }
  };

  const handleSave = async () => {
    if (!id) {
      message.error('No ID provided for saving');
      return;
    }

    try {
      const headers = await getAuthHeaders();
      const state: PlanalyzerState = {
        currentFixedCosts,
        newFixedCosts,
        currentPlans,
        newPlans
      };

      const jsonString = JSON.stringify(state);
      
      await axios.patch(
        `${API_BASE_URL}/updateRapidPlanalyzer`,
        {
          id,
          ledgersave: jsonString
        },
        { headers }
      );

      message.success('Analysis saved successfully');
    } catch (error) {
      console.error('Failed to save:', error);
      message.error('Failed to save analysis');
    }
  };

  return {
    currentFixedCosts,
    newFixedCosts,
    currentPlans,
    newPlans,
    handleFixedCostChange,
    handlePlanChange,
    addPlan,
    removePlan,
    handleSave,
    setCurrentFixedCosts,
    setNewFixedCosts,
    setCurrentPlans,
    setNewPlans
  };
};
